.site-footer {
  height: (--footer-height);
  padding-top: 0.5rem;
  // background: #1F2A55;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100%!important;
  background: #1E1E1E;
  z-index: 3;
  position: relative;
.container-ps-footer{
  padding: 1.5rem 4rem 1.5rem 2rem;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100%!important;
}
  .footer-link,
  .copyright-text {
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    line-height: 20px;
  }

  .footer-link {
   // margin-right: 100px;
    color: #fff !important;
    display: inline-block;
    margin: 6px 0px;
    padding: 0 0.5rem;
    color: #fff!important;
  }
  .Border{
    border-right: 1px solid #fff;
  }
  .last{
    margin-right: 100px;
 
  }
  .footer-link-main{
    color: #fff !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
    line-height: 20px;
  }
  .footer-logo{
    width:15.5rem;
  }
  .Zfooter-logo{
    width:12rem;
    margin-top: 1.2rem;
  }
  .fa{
    color: #1E1E1E;
    font-size: 18px;
  }
  .icondiv{
    background-color: #fff;
    border-style: none;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
  }.icondiv:hover{
    background-color: #3F3F3F;
    .fa{
      color: #fff;
    }
  }
  .colorFFF{
    color: #fff;
    font-size: 20px;

  }
  .contactparamail{
    .contact-header-mail{
          color: #FFFFFF;
          font-family: "Open Sans", Sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          -webkit-text-stroke-color: #000;
          stroke: #000;
          margin-bottom: 5px;
    }
    a{
      color: #fff;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .link-row{
    display: block;
  }
  .link-row{
    display: block;
  }
  .text-justify-lg{
    text-align: center;
  }
  @media (max-device-width:500px) {
    .Zfooter-logo{
      margin-left: 0rem!important;
    }
    .bgDarkBlue{
      padding: 1rem 2rem!important;
     }
  }
  @media (min-device-width:450px) and (max-device-width:820px) {
    .EmailSubmit {
      padding: 13px 20px;
    }
    .mx-5-mid{
      margin-right: 3rem;
      margin-left: 3rem;
    }
  .text-justify-lg{
    text-align: center;
  }
  }
  @media (max-device-width:449px){
    .mx-5-mid{
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
  .text-justify-lg{
    text-align: center;
  }
  }
  .p15{
    padding-left: 15px;
   } 
   .contact-header{
    margin-bottom: 13px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    -webkit-text-stroke-color: #000;
    stroke: #000;
   }
   .contactpara{
    p{
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
   }
   .bgDarkBlue{
    background-color: #1E1E1E;
    padding: 1rem 2rem;
    // position: absolute;
   }
   .nopadding{
    .col-2{
  padding: 0px;
    }
    .col-10{
      padding: 0px;
    }
  }
}
