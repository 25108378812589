/* Common Css Start */
.home-page-conatiner{
  background-color: #fff !important;
  font-family: Montserrat !important;
}

.main-container {
  position: relative;
  padding: 115px 15px 60px 15px !important;
  min-height: calc(100vh - 200px);
}

.lp-main-container{
  position: relative;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.font-size-9 {
 font-size: 9px;
}
.font-size-10 {
 font-size: 10px;
}
.font-size-11 {
 font-size: 11px;
}
.font-size-12 {
 font-size: 12px;
}
.font-size-13 {
 font-size: 13px;
}
.font-size-14 {
 font-size: 14px;
}
.font-size-15 {
 font-size: 15px;
}
.font-size-16 {
 font-size: 16px;
}
.font-size-18 {
 font-size: 18px;
}
.font-size-19 {
 font-size: 19px;
}
.font-size-20{
 font-size: 20px;
}
.font-size-22{
 font-size: 22px;
}
.font-size-25{
 font-size: 25px;
}

.font-color-grey {
  color: #999999;
}

.font-color-white {
  color: #ffffff !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: bold;
}

.pt-80 {
  padding-top: 80px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-18 {
  padding-top: 18px;
}

.pt-110 {
  padding-top: 9rem;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.row-mt {
   padding-left: 50px;
   padding-right: 50px;
}

.text-center {
  text-align: center;
}

.pl-45 {
  padding-left: 45px;
}

.pl-85 {
  padding-left: 85px
}

.btn-pt-25 {
  padding-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: -18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mtm-3 {
  margin-top: -5px;
}

.ml-40 {
  margin-left: 40px;
}

.section-pb-50 {
  padding-bottom: 50px !important;
  padding-top: 0 !important
}

.section-pb-0 {
  padding: 0 !important;
}

button.btn.btn-primary.sm-white {
    width: 105px;
    max-width: 200px;
    padding: 2px 20px;
    font-size: 11px;
    background-color: #fff;
    color: #1b3272;
    border-radius: 3px;
    margin-right: 8px;
    margin-left: 17px;
    font-size: 13px;
}
 .sm-primary {
    /* width: 105px;
    min-width: 105px; */
    padding: 1px 20px;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border-radius: 3px !important;
}
.sm-primary-1{
  font-size: 17px!important;
}
.btn-lg-white {
  padding: 8px 20px;
  font-size: 11px;
  background-color: #fff;
  color: #1b3272 !important;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  font-weight: 600;
  letter-spacing: .5px;
}

.btn-lg-white:hover {
  background-color: #1b3272;
  color: #fff !important;
}
#yearly-Report h1{
font-size: 30px;
}
#yearly-Report .vi-info-p p{
  font-size: 13px!important;
}
#yearly-Report .ReportSubHeading{
  font-size: 22px;
}
#yearly-Report .read-more-btn{
  padding: 10px 15px;
    font-size: 14px;
}
h1 {
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #131415;
  padding-bottom: 15px;
}

.h3-text {
  font-weight: bold;
  letter-spacing: -0.38px;
  color: #131415;
}

.float-r {
  float: right !important;
}

.float-l {
  float: left;
}

.sm-card {
 width: 200px;
 height: 230px;
 border-radius: 5px;
 margin-left: 15px;
 box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
 background-blend-mode: overlay, normal;
 background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.lg-card {
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  width: 250px;
  min-height: 375px;
  border-radius: 10px;
  background-blend-mode: overlay, normal;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
}

.border, .loader-demo-box {
  border: none !important;
}
/* Common Css End */


/* Header Css Start */

.main-hb img {
    height: auto;
    object-fit: contain;
    width: 35px;
    height: 30px;
}

.formpage header a.logo img {
    max-height: 80px !important;
}

.navbar-top {
  box-shadow: 5px 1px 10px #c1bcbc;
}

 ul#accordion {
    padding-top: 20px !important;
}

.p-img img{
  width: 28px !important;
}

/* @media (min-width: 768px){
  ul#accordion {
    padding-top: 20px !important;
  }
} */

/* .login-btn-wh {
    padding: 5px 20px !important;
    background-color: #fff !important;
    border: 1px solid #1b3272;
    border-radius: 3px;
    font-size: 11px !important;
    font-weight: 600;
    color: #1b3272 !important;
    margin-top: -4px;
    letter-spacing: .5px;
    line-height: inherit;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
    text-transform: uppercase;
    width: auto;
    display: inline-block;
} */

@media (min-width: 768px){
  .formpage nav.navbar.navbar-default ul.nav>li>a.login-btn-wh  {
      padding: 5px 20px !important;
  }
}

.header-main .dropdown:hover ul.dropdown-menu.animated {
    left: 20px !important;
}

.formpage .dropdown:hover ul.dropdown-menu.animated {
    top: 35px !important;
}

.btn-w-135 {
  width: 135px !important;
}


/* Header Css End */
main-hb img {
  max-width: 85% !important;
  height: auto;
  border-radius: 50%;
  object-fit: contain;
  width: 35px;
  height: 30px;
}

.formpage header a.logo img {
  max-height: 80px !important;
}

.navbar-top {
box-shadow: 5px 1px 10px #c1bcbc;
}

ul#accordion {
  padding-top: 20px !important;
}

.p-img img{
width: 28px !important;
}

/* @media (min-width: 768px){
ul#accordion {
  padding-top: 20px !important;
}
} */

/* .login-btn-wh {
  padding: 5px 20px !important;
  background-color: #fff !important;
  border: 1px solid #1b3272;
  border-radius: 3px;
  font-size: 11px !important;
  font-weight: 600;
  color: #1b3272 !important;
  margin-top: -4px;
  letter-spacing: .5px;
  line-height: inherit;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
  text-transform: uppercase;
  width: auto;
  display: inline-block;
} */

@media (min-width: 768px){
.formpage nav.navbar.navbar-default ul.nav>li>a.login-btn-wh  {
    padding: 5px 20px !important;
}
}

.header-main .dropdown:hover ul.dropdown-menu.animated {
  left: 20px !important;
}

.formpage .dropdown:hover ul.dropdown-menu.animated {
  top: 35px !important;
}

.btn-w-135 {
width: 135px !important;
}
/* Home Page Css Start */


/* Home Page Css End */

/* API Page Css Start */

.sm-card .card-body {
  padding: 25px 20px;
}

.sm-card .card-body img {
  width: 90%;
  margin-bottom: 25px;
}

.sm-card .card-text {
  font-weight: 600;
  line-height: 1.27;
}
/* API Page Css End */

/* Subscribe Page Css Start */
.mailchimp-form form {
    display: block;
    position: relative;
    text-align: left;
}

.mailchimp-form {
    background: #fff;
    clear: left;
    font: 14px 'Montserrat', sans-serif;
}

.mailchimp-form input {
    border: 1px solid #ABB0B2;
    border-radius: 3px;
    margin-bottom: 40px;
    width: 65%;
    padding: 8px 15px;
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    background-color: #ffffff;
    font-size: 12px;
}

.mailchimp-form button {
    display: block;
    padding: 10px 20px;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
    width: 65%;
    font-weight: 600;
    letter-spacing: .5px;
}
/* Subscribe Page Css End */

/* Insight Page Css Start */
.nav-list {
    padding-top: 45px;
    padding-bottom: 20px;
}
.nav-list ul {
    list-style: none;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
}

.nav-list ul li {
  display: inline-block;
  padding: 0.30rem 1.5rem;
  cursor: pointer;
  color: #353333;
  font-size: 12px;
}

.nav-list li.active {
  border-bottom: 3px solid #070763;
  font-weight: bold;
  letter-spacing: 1.9px;
  text-align: center;
  color: #070763;
  font-size: 12px;
}
.nav-list li a {
  color: inherit!important;
}
.lg-card .card-text {
  font-weight: 600;
  line-height: 1.27;
  font-weight: bold;
  letter-spacing: -0.24px;
  text-align: center;
  color: #2b292d;
}

.lg-card .card-pera {
   font-weight: 600;
   line-height: 1.25;
   text-align: center;
   color: #a0acba;
}.lg-card .card-pera1 {
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: #a0acba;
  text-align: justify!important;
}
.lg-card1{
  width: 330px;
}
.lg-card .card-body {
    /* padding: 20px 25px !important; */
    padding: 20px 12px !important;
}

.lg-card .card-img-top {
  border-radius: 10px;
}

/* .lg-card:hover {
	-webkit-transform: translate(0, -8px);
	-moz-transform: translate(0, -8px);
	-ms-transform: translate(0, -8px);
	-o-transform: translate(0, -8px);
	transform: translate(0, -8px);
	box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
} */

.lg-card:hover .card-img-top {
  opacity: 0.3;
}


.lr-btn-block {
  display: block;
}

.lr-btn {
  padding: 8px 20px;
  margin-top: 35px;
}

.vi-tab .vi-img {
  width: 275px;
  height: 190px;
  border-radius: 15px;
}

.vi-tab .card .card-body {
    padding: 1.25rem;
}

.vi-tab .text-left {
  padding-left: 0;
}

.vi-card-bold {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  color: #131415;
}

.vi-card-pera {
  font-weight: 500;
  line-height: 1.75;
  color: #666666;
}

.vi-rm-btn {
  font-weight: 600;
  color: #070763;
}

.vi-rm-btn:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #070763;
}

/* Insight Page Css End */


/* Visulation DEtial Page Css Start */

img.vi-info-img {
  width: 75%;
  height: auto;
}

.vi-info-p {
  font-weight: 500;
  line-height: 1.75;
  color: #131415;
}

.vi-info-p iframe {
  width: 99% !important;
  height: 545px;
}

.vi-info-p p,
.vi-info-p ul li,
.vi-info-p ol li {
  font-size: 12px;
  text-align: justify;
}

.vi-info-blue-block {
  font-size: 30px;
  height: 55px;
  line-height: 22px;
  padding: 15px 0;
  width: 55px;
  font-weight: 500;
  margin-top: 5px;
  float: left;
  margin: 0 20px 0 0;
  text-align: center;
  background-color: rgb(27, 50, 114);
  color: #fff;
}

/* Visulation DEtial Page Css End */

/* API doc Page Css Start */

.sidebar {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
  position: sticky;
  height: 400px;
  overflow: auto;
  /* width: auto !important; */
  width: 230px !important;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
  font-weight: bold;
}

.activeMenu {
  background-color: #c1c1c1;
  font-weight: bold;
}

/* .sticky {
  position: sticky !important;
  top: 0;
}

.content-section {
   background: lavender;
   height: 1000px;
   min-height: 100vh;
   padding: 1em;
 }

 .sidebar-section {
   height: 100%;
 }

 .sidebar-content {
   background: cornflowerblue;
   padding: 1em;
 }

 .placeholder-example {
       background: lavender;
       height: 100vh;
       padding: 1em;
     }

     .sticky-example {
       background: cornflowerblue;
       padding: 1em;
     } */
/* API doc Page Css End */

/* Pricing Page Css Start */
.plan-card {
  min-height: 350px !important;
}

.plan-card:hover {
	-webkit-transform: translate(0, -20px);
	-moz-transform: translate(0, -20px);
	-ms-transform: translate(0, -20px);
	-o-transform: translate(0, -20px);
	transform: translate(0, -20px);
	box-shadow: 0 40px 40px rgba(0, 0, 0, 0.2);
}

.plan-price-tag {
  padding-top: 30px;
}

span.plan-ht {
  font-size: 32.4px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: #2b292d;
  /* margin-right: 10px; */
}

span.plan-free{
  font-size: 28px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: #2b292d;
}

span.plan-gery-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 0.96;
  text-align: center;
  color: rgba(43, 41, 45, 0.4);
}

.plan-card-body .card-pera {
  color: #2b292d !important;
}

.plan-card-body button {
  display: block;
  padding: 6px 20px !important;
  font-size: 11px;
  background-color: #1b3272;
  color: #fff;
  border: 1px solid #1b3272;
  border-radius: 3px !important;
  width: 100%;
  font-weight: 600;
  letter-spacing: .5px;
}

.plan-body-li ul {
  list-style: none;
  text-align: left;
  margin-left: -38px;
  min-height: 86px;
}

.plan-body-li ul li {
  font-size: 11px;
  font-weight: 500;
  line-height: 2;
  color: #2b292d;
}

.plan-body-li ul li:before {
  content: '✓';
  padding-right: 5px;
}

/* .section-pb-50 tr {
  border-radius: 12px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(216, 216, 216, 0.65);
  background-color: #fff;
  margin-top: 8px;
  display: block;
  vertical-align: middle;
  font-size: 12px;
}
.section-pb-50 thead tr {
  background-color: #d8d8d8;
}
.section-pb-50 .table th {
    border: solid 1px rgba(216, 216, 216, 0.65) !important;
    font-size: 12px;
} */

.section-pb-50 .table {
  font-size: 12px;
}

.accordion-tag .card {
  background-color: #f3f6ff;
  padding: 10px 5px;
  margin-bottom: 10px;
  border: 1px solid #ece7e7;
  /* background-color: white; */
  box-shadow: 1px 3px 5px #d4d1d1;
  border-radius: 0 !important;
}

.accordion-tag p {
  line-height: 1.5;
  font-size: 13px!important;
}

.accordion-tag a {
  color: #131415 !important;
}

.accordion-tag span.block-title {
    float: left;
    font-weight: 600;
}

.accordion-tag i {
    float: right;
    font-weight: 600;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.plan-tab {
  padding-top: 45px;
  padding-bottom: 20px;
  text-align: center;
}

.plan-tab ul {
  list-style: none;
}

 .plan-tab li {
  display: inline-block;
  padding: 4px 1.5rem;
  cursor: pointer;
  padding: 8px 18px;
}

 .plan-tab li.active {
   color: #070763;
   border-bottom: 1px solid #070763;
}

 nav.plan-tab.mbt-35 {
  text-align: center;
}

 .plan-tab ul {
    list-style: none;
    margin: 0 0 25px 0px;
    padding: 0;
    display: inline-block;
}

 .plan-tab ul li {
    float: left;
    border: 1px solid #070763;
    font-size: 12px;
    font-weight: bold;
}

 .plan-tab ul li:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
}

.plan-tab ul li:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

 .plan-tab li.active {
    color: #fff;
    background: #070763;
    border: 1px solid #070763;
    padding: 8px 18px;
}

/* Pricing Page Css End */

.rsc {
    position: fixed;
    right: 17px;
    bottom: 17px;
    z-index: 1;
}

video#video-background {
    object-fit: cover;
}

/* Comming soon css */
.comingsoonPage {
	 height: auto;
}
 .comingsoonPage p, .comingsoonPage ul, .comingsoonPage li {
	 color: gray;
}
 .comingsoonPage .coming-soon-container {
	 background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.pexels.com/photos/2812697/pexels-photo-2812697.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260) no-repeat center center/cover;
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
}
 .comingsoonPage .coming-soon-container .row {
	 transform: translatey(50%);
}
 .comingsoonPage .coming-soon-container .row .mainText {
	 font-size: 90px;
	 color: #fff;
	 line-height: 1.1;
	 font-weight: 700;
}
 .comingsoonPage .coming-soon-container .row .secondaryText {
	 font-size: 20px;
	 color: #ccc;
	 line-height: 1.2;
}
 .comingsoonPage .coming-soon-container .row .form-container {
	 margin-top: 50px;
}
 .comingsoonPage .coming-soon-container .row .form-container form {
	 width: 40%;
	 position: relative;
	 background-color: transparent;
	 height: 60px;
	 padding-right: 80px;
	 background-color: rgba(255, 255, 255, 0.1);
}
 .comingsoonPage .coming-soon-container .row .form-container form input {
	 margin: 0px;
	 display: block;
	 width: 100%;
	 height: 100%;
	 padding: 0 15px 0 25px;
	 background-color: transparent;
	 font-size: 16px;
	 border: none;
	 font-weight: 600;
	 color: #fff;
}
 .comingsoonPage .coming-soon-container .row .form-container form button {
	 margin: 0px;
	 width: 80px;
	 height: 100%;
	 position: absolute;
	 right: 0px;
	 top: 0px;
	 font-size: 14px;
	 color: #fff;
	 background-color: transparent;
	 border: 0px;
}
 .comingsoonPage .coming-soon-container .row a.back {
	 background-color: rgba(255, 255, 255, 0.1);
	 height: 50px;
	 color: #fff;
	 font-size: 14px;
	 margin-top: 30px;
	 text-decoration: none;
	 line-height: 50px;
	 width: 200px;
	 text-align: center;
}

/* Comming soon end css */

@media (min-width: 992px) {
.ticker-main-div .col-lg-2 {
    flex: 0 0 19.66667% !important;
    max-width: 19.66667% !important;
}


}

.plan-tab li a{
  color:  #211f72!important;
}


.plan-tab li.active a{
  color: white !important;
}

 .plan-tab ul li:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
}

.plan-tab ul li:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

 .plan-tab li.active {
    color: #fff;
    background: #070763;
    border: 1px solid #070763;
    padding: 8px 18px;
}
/* Blog Page */
.blog-detail-image .img-fluid{
  width: 50%!important;
  height: 20rem!important;
}
.blog-content-div{
  background: #fff!important;
}
.blog-image-div{
margin: 5% 0%;
}
.BlogShare{
  padding-right: 0.5rem;
  display: grid!important;
}
.blog-content{
margin-right: 3.8em;
  margin-bottom: 3em;
}
.blogShareIcons .fa
{
  color: #090763;
  font-size: 25px;

}
.blogShareIcons .fa-link{
  cursor: pointer;
}
.site-page__heading {
 color:#211f72;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: -0.13px;
  text-transform: uppercase;
}
.padding-top{
  padding-top: 5rem;
}
.padding-right{
  padding-right: 1.9rem!important;
}
.margin-top {
  margin-top: 20rem;
}