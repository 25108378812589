.header-ps{
    .header-padding{
        padding: 4px 12px;
    }
    background-color: #1E1E1E!important;
    .login-desi{
        background-color: #7854F7!important;
        border: 1px solid #fff!important;
        padding: 4px 20px;
        
    }
    .btn-w-135{
        width: 168px !important;
        background-color: #B9B9B9!important;
        color: #000!important;
        border-color:#B9B9B9 !important;
        padding: 4px 20px!important;
    }
    .custom{
        color:#04021C;
        font-weight: 600;
        font-size: 23px;
    }
    .navbar-brand img{
        width: 10rem;
    }
    @media (max-width:500px) {
        .navbar-brand img{
            height: 4.125rem;
            width: 14rem;
            margin-left: 0rem;
              }
    }
}
.navbar-light .menulinks .nav-link   {
    color:#fff!important;
    font-weight: 500;
    font-size: 15px;
}
.navbar-light .menulinks .fontw-600{
    font-weight: 900!important;
}
.loginBTN{
    background-color: #1e1e1e !important;
    border: 1px solid #fff !important;
    padding: 4px 20px!important;
    margin: 0px 20px!important;
    font-size: 14px;
    color: #fff!important;
}