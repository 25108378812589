.slick-prev:before{
    color: #000!important;
}
.slick-next:before{
    color: #000!important;
}
 .slick-center .text {
    color: #e67e22!important;
    opacity: 1;
 height: 400px!important;
}

.slick-slide{
    padding-top: 4.5rem;
    height: 150px;
}
// .slick-prev{
//     display: none!important;
// }
.slick-slide .text{
    height: 150px;
}
.slick-center {
    padding-top: 0rem;
}
.react-stacked-center-carousel-slide-0  .carousel-Div .text{
    height:300px!important
}
.carousel-Div .text{
    transition: all 300ms ease;
    cursor: pointer;
    width: 100%;
    border-radius: 15px;
    padding: 2%;
    height:100px;
    position: relative;
    border: 1px solid #C6C6C6;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.05);
}
.Made-For-a-Purpose {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #04021C;

  }
  .purpose p {
    font-weight: 500;
    line-height: 1.33;
  
    color: #2b292d;
 
  }
  .p-0{
    padding: 0!important;
  }
  
.prevCard {
display: none;
}

.activeCard{
 .card{
    border-radius: 42px;
    padding: 48px 35px;
    position: relative;
    transition: width 1s ease-in-out;
    background-color: #fff;
    width: 460px!important;
    min-width: 460px!important;
    transition: all .2s linear;
    height: 450px;
}
}
.height{
    height: 200px;
    width:200px;
}
.nextCard{ 
    position: relative;
    width: 210px;
    min-width: 210px;
    height: 140px;
    margin-left: 30px;
    .card {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: #fff;
        padding-left: 8%;
        padding-right: 8%;
        opacity: 0.5;
    
 img{
    display: none;
 }
}

}
.nextCard1{ 
    position: relative;
    width: 210px;
    min-width: 210px;
    height: 140px;
    margin-left: 30px;
    .card {
        opacity: .5;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: #fff;
        padding-left: 8%;
        padding-right: 8%;
     img{
    display: none;
     }
 }

}
.nextCard2{ 
    position: relative;
    width: 210px;
    min-width: 210px;
    height: 140px;
    margin-left: 30px;
    .card {
        opacity: .5;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background: #fff;
        padding-left: 8%;
        padding-right: 8%;
     img{
    display: none;
     }
 }

}
.fade-out{
    animation: fadeOut .5s ease-out forwards;
    -webkit-animation: fadeOut .5s ease-out forwards;
}
@keyframes fadeOut{
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    
    100% {
        opacity: 0;
        transform: translateY(30px);
    }
}
.move-in{
    img{
        display: none;
    }
    h2{
        position:relative;
    }
    animation: moveCard1 .5s ease-out forwards;
    -webkit-animation: moveCard1 .5s ease-out forwards;
}
.move-in.card{
justify-content: flex-start!important;
align-items: flex-start!important;
h2{
    font-size: 20px;
}

}
@keyframes moveCard1 {
    0% {
        transform: translateX(0);
        width: 100%;
        min-width: 100%;
        height: 100%;
        box-shadow: none;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }
    100% {
        transform: translateX(-250px) translateY(-144px);
        opacity: 1;
        height: 430px;
        width: 460px;
        min-width: 460px;
        align-items: flex-start;
        justify-content: flex-start;
        box-shadow: 0 50px 60px rgba(39,51,64,.05);
        padding: 25px 35px;
        text-align: left;
    }
}
.move-in2{
    animation: moveCard2 .5s ease-out forwards;
    -webkit-animation: moveCard2 .5s ease-out forwards;
}
@keyframes moveCard2 {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-255px);
    }
    
}
.move-in3{
    animation: moveCard3 .5s ease-out forwards;
    -webkit-animation: moveCard3 .5s ease-out forwards;
}
@keyframes moveCard3 {
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-255px);
    }
    
}
.overflow{
    overflow:hidden;
}
.fade-in-image{
    animation: fadeIn .25s ease-out forwards;
    -webkit-animation: fadeIn .25s ease-out forwards;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    } 
}
.nxtButton{
    position: absolute;
    padding: 14px;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    background: #1E1E1E;
    border: 1px solid #1E1E1E ;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    top: 0%;
    /* right: 54%; */
    /* top: 44%; */
    z-index: 10;
    top: 47%;
    right: 55%;
    .fa{
         color: #fff;
         font-size: 20px;
    }
}
.nxtButton:hover{
    animation: shake1 1.82s cubic-bezier(.36,.07,.19,.97) both;
    background: #fff !important;
    .fa{
        color:#1E1E1E;
    }
}

@media (max-width: 1023px) {
    /* CSS rules specific to smaller screens */
    .nxtButton{
        position: relative;
        margin-top: 10px;
        margin-left: 115px;
        top: 0%;
        right: 0%;
       // background-color:#757482;
    }
    .activeCard{
        .card{
           border-radius: 20px;
           padding: 35px 25px;
           position: relative;
           transition: width 1s ease-in-out;
           background-color: #fff;
           width: 230px!important;
           min-width: 230px!important;
           transition: all .2s linear;
           height: 350px;
           
       }
       .img-thumbnail{
        margin-left: -10px;
       }
       }
       
       .displayMobileView{
        display: none!important;
       }
  
  }
  @media (max-width: 1439px) and (min-width: 1024px) {
    /* CSS rules specific to smaller screens */
    .nxtButton{
     //   margin-top: 10px;
     //   margin-left: 140px;
        top: 40%;
       right: 51%;
       // background-color:#757482;
    }
    .activeCard{
        .card{

           border-radius: 20px;
           padding: 35px 25px;
           margin-left:25px;
           position: relative;
           transition: width 1s ease-in-out;
           background-color: #fff;
           width: 250px!important;
           min-width: 250px!important;
           transition: all .2s linear;
           height: 350px;
           
       }
       .img-thumbnail{
        margin-left: 10px;
       }
       }
       @keyframes moveCard1 {
        0% {
            transform: translateX(0);
            width: 100%;
            min-width: 100%;
            height: 100%;
            box-shadow: none;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
        }
        100% {
            transform: translateX(-231px) translateY(-104px);
            opacity: 1;
            height: 350px;
            width: 300px;
            min-width: 230px;
            align-items: flex-start;
            justify-content: flex-start;
            box-shadow: 0 50px 60px rgba(39, 51, 64, 0.05);
            padding: 25px 35px;
            text-align: left;
        }
    }
  }
  @media (max-width: 2561px) and (min-width: 1440px) {
    /* CSS rules specific to smaller screens */
    // .nxtButton{
    //     position: relative;
    //     margin-top: 10px;
    //     margin-left: 160px;
    //     border-radius: 15px;
    //     border-color: #000;
    //    // background-color:#757482;
    // }
    .activeCard{
        .card{
           border-radius: 20px;
           padding: 35px 25px;
           position: relative;
           width: 325px!important;
           transition: width 1s ease-in-out;
           background-color: #fff;
          // width: 230px!important;
           min-width: 230px!important;
           transition: all .2s linear;
           height: 350px;
           
       }
       .img-thumbnail,h2{
        margin-left: 40px;
       }
       }
       
    //    .displayMobileView{
    //     display: none!important;
    //    }
       @keyframes moveCard1 {
        0% {
            transform: translateX(0);
            width: 100%;
            min-width: 100%;
            height: 100%;
            box-shadow: none;
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
        }
        100% {
            transform: translateX(-321px) translateY(-109px);
            opacity: 1;
            height: 355px;
            width: 325px;
            min-width: 230px;
            align-items: flex-start;
            justify-content: flex-start;
            box-shadow: 0 50px 60px rgba(39, 51, 64, 0.05);
            padding: 25px 35px;
            text-align: left;
        }
    }
  }
  @keyframes shake1 {
  10%, 90% {
    transform: translate3d(-5px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(5px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-5px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(5px, 0, 0);
}
  }