@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

.formpage header a.logo img {
    max-height: 65px;
}

.lg-card {
    margin: 0px auto;
    }
    

.accordion-menu{
    width: 100%;
    background-color: #fff;
}

@media (min-width:768px) {
    .formpage div#navbar-collapse-toggle-1 {
        margin-top: -12px;
    }
    .formpage li.dropdown.simple-dropdown {
        line-height: 1;
      
    }
    li.dropdown.simple-dropdown.topm {
        margin-top: 3px;
        padding-bottom: 20px;
    }
    .formpage nav.navbar.navbar-default ul.nav>li>a {
        padding: 20px 0 !important;
    }
    ul#accordion {
        padding-top: 30px;
    }
}

.formpage .page-title-small .Sectionhead {
    display: block;
    width: 100%;
    font-size: 50px;
    font-family: oswald, sans-serif;
}

.formpage .bg-light-gray {
    background-color: #1b3272;
}

.formpage nav.navbar.bootsnav ul.nav>li.dropdown.simple-dropdown.butn a {
    background: #1b3272!important;
    PADDING: 10PX 20PX !important;
    COLOR: #FFF!important;
    BORDER-RADIUS: 18PX;
}

.formpage .simple-dropdown.dropdown {
    position: relative;
}


/* ===================================
    Reset
====================================== */

html .formpage {
    font-size: 15px;
}

body .formpage {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
    line-height: 24px;
}

body .formpage,
html  .formpage{
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
}

.formpage a ,
.formpage a:active,
.formpage a:focus {
    color: gray;
    text-decoration: none;
}

.formpage a:hover,
.formpage a:active {
    
    text-decoration: none;
}

.formpage a:focus,
.formpage a:active,
.formpage button:focus,
.formpage button:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

.formpage img {
    max-width: 100%;
    height: auto;
}

.formpage video {
    background-size: cover;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.formpage input,
.formpage textarea,
.formpage select {
    border: 1px solid #d1d1d1;
    font-size: 14px;
    padding: 8px 15px;
    width: 100%;
    margin: 0 0 20px 0;
    max-width: 100%;
    resize: none;
    color: inherit;
}

.formpage input[type="submit"] {
    width: auto
}

input[type="button"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="submit"] {
    -webkit-appearance: none;
    outline: none;
}

input:focus,
textarea:focus {
    border-color: #585858 !important;
    outline: none;
}

input[type="button"]:focus,
input:focus,
textarea:focus,
select:focus,
.form-control:focus,
.btn.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

.formpage select::-ms-expand {
    display: none;
}



.formpage p {
    margin: 0 0 25px
}

.formpage b,
.formpage strong {
    font-weight: 600;
}

.formpage .last-paragraph-no-margin p:last-of-type {
    margin-bottom: 0
}

.formpage .alt-font strong {
    font-weight: 700
}

.formpage ul,
.formpage ol,
.formpage dl {
    list-style-position: outside;
    
}

.formpage * {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

.formpage *:hover {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

.formpage ::selection {
    color: #000;
    background: #dbdbdb;
}

.formpage ::-moz-selection {
    color: #000;
    background: #dbdbdb;
}

.formpage ::-webkit-input-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis;
}

.formpage ::-moz-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis;
    opacity: 1;
}

.formpage :-ms-input-placeholder {
    color: #6f6f6f;
    text-overflow: ellipsis;
    opacity: 1;
}


/* ===================================
    Typography
====================================== */


/* font family */


.formpage nav.navbar.navbar-default.bootsnav.background-white.header-light.navbar-top.navbar-expand-lg.on.no-full {
    left: 0;
}
.formpage .alt-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500
}
.formpage .dropdown:hover ul.dropdown-menu.animated {
    display: block;
    top: 20px;
    left:-42px;
    border: 0;
}

.formpage .main-font {
    font-family: 'Roboto', sans-serif;
}
.formpage .menulinks a, .formpage .menulinks a:hover{
    color: #1b3272;
    opacity: 1;
}
li.dropdown.simple-dropdown.butn {
    margin-top: 10px;
}
.formpage .bgadd{
    /* background: url(https://www.amlpenalties.com/pofo/images/heading.jpg) 0% 0% / cover; */
    padding-top: 200px;
    
}
.formpage footer{
    padding:20px 0;
}
/* heading */
/* 
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 25px;
    padding: 0;
    letter-spacing: 0;
    font-weight: 400;
}

h1 {
    font-size: 70px;
    line-height: 70px;
}

 h2 {
    font-size: 55px;
    line-height: 60px
} 

h3 {
    font-size: 48px;
    line-height: 54px
}

h4 {
    font-size: 40px;
    line-height: 46px
}

h5 {
    font-size: 32px;
    line-height: 40px
}

h6 {
    font-size: 25px;
    line-height: 30px
} */


/* text size */

.formpage .text-extra-small {
    font-size: 11px;
    line-height: 14px
}

.formpage .text-small {
    font-size: 12px;
    line-height: 20px
}

.formpage .text-medium {
    font-size: 16px;
    line-height: 23px
}

.text-large {
    font-size: 18px;
    line-height: 26px
}

.text-extra-large {
    font-size: 20px;
    line-height: 26px
}

.title-large {
    font-size: 100px;
    line-height: 95px
}

.title-extra-large {
    font-size: 130px;
    line-height: 120px
}


/* text color */

.text-white-2,
.btn.text-white-2 {
    color: #FFF
}

.text-black,
.btn.text-black {
    color: #000
}

.text-extra-dark-gray,
.btn.text-extra-dark-gray {
    color: #232323
}

.text-dark-gray,
.btn.text-dark-gray {
    color: #626262
}

.text-extra-medium-gray,
.btn.text-extra-medium-gray {
    color: #757575
}

.text-medium-gray,
.btn.text-medium-gray {
    color: #939393
}

.text-extra-light-gray,
.btn.text-extra-light-gray {
    color: #b7b7b7
}

.text-light-gray,
.btn.text-light-gray {
    color: #d6d5d5
}

.text-very-light-gray,
.btn.text-very-light-gray {
    color: #ededed
}

.text-deep-pink,
.btn.text-deep-pink {
    color: #ff214f
}


/* dropcap */

.first-letter {
    float: left;
    font-size: 50px;
    line-height: auto;
    margin: 0 20px 0 0;
    text-align: center;
    padding: 10px 0;
    font-weight: 600
}

.first-letter-big {
    float: left;
    font-size: 110px;
    line-height: 110px;
    margin: 0 20px 0 0;
    padding: 0 8px;
    text-align: center;
    font-weight: 600;
    position: relative;
}

.first-letter-big:before {
    position: absolute;
    border-bottom: 1px solid;
    content: "";
    display: block;
    width: 100%;
    top: 55%;
    left: 0
}

.first-letter-block {
    font-size: 30px;
    height: 55px;
    line-height: 22px;
    padding: 15px 0;
    width: 55px;
    font-weight: 500;
    margin-top: 5px
}

.first-letter-block-round {
    border-radius: 6px;
    border: 1px solid;
}

.first-letter-round {
    border-radius: 50%;
    font-size: 35px;
    padding: 15px;
    width: 65px;
    height: 65px;
    line-height: 32px;
}


/* blockquote */

blockquote {
    padding: 20px 30px
}

blockquote p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 17px !important;
    font-weight: 300
}

blockquote {
    border-left: 2px solid;
    padding: 15px 40px;
    margin: 45px 0
}

blockquote footer {
    color: #939393;
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
    content: '\2014 \00A0';
}

.blog-image blockquote {
    padding: 60px;
    border: 0;
    margin: 0;
}

.blog-image blockquote h6:before {
    content: "\e67f";
    font-family: 'themify';
    font-size: 40px;
    top: 5px;
    position: relative;
    margin-right: 12px;
    color: #ff214f
}


/* text link hover color */

a.text-link-white-2,
a.text-link-white-2 i {
    color: #ffffff
}

a.text-link-white-2:hover,
a.text-link-white-2:hover i,
a.text-link-white-2:focus,
a.text-link-white-2:focus i {
    color: #ff214f
}

a.text-link-black,
a.text-link-black i {
    color: #000000
}

a.text-link-dark-gray {
    color: #939393
}

a.text-link-dark-gray:hover,
.text-link-dark-gray:focus {
    color: #232323
}

a.text-link-extra-dark-gray {
    color: #232323
}

a.text-link-extra-dark-gray:hover,
a.text-link-extra-dark-gray:focus {
    color: #000000
}

a.text-link-deep-pink,
a.text-link-deep-pink i {
    color: #ff214f
}

a.text-link-deep-pink:hover,
a.text-link-deep-pink:hover i,
a.text-link-deep-pink:focus,
a.text-link-deep-pink:focus i {
    color: #fff
}


/* hover color */

a.text-white-2-hover:hover,
a.text-white-2-hover:focus {
    color: #fff !important;
}

a.text-black-hover:hover,
a.text-black-hover:focus {
    color: #000 !important;
}

a.text-deep-pink-hover:hover,
a.text-deep-pink-hover:focus {
    color: #ff214f !important;
}

a.text-extra-dark-gray-hover:hover {
    color: #232323 !important;
}

a.text-dark-gray-hover:hover {
    color: #626262 !important;
}

a.text-extra-medium-gray-hover:hover {
    color: #757575 !important;
}

a.text-medium-gray-hover:hover {
    color: #939393 !important;
}

a.text-extra-light-gray-hover:hover {
    color: #b7b7b7 !important;
}

a.text-light-gray-hover:hover {
    color: #d6d5d5 !important;
}

a.text-very-light-gray-hover:hover {
    color: #ededed !important;
}


/* letter spacing */

.no-letter-spacing {
    letter-spacing: 0px
}

.letter-spacing-1 {
    letter-spacing: 1px
}

.letter-spacing-2 {
    letter-spacing: 2px
}

.letter-spacing-3 {
    letter-spacing: 3px
}

.letter-spacing-4 {
    letter-spacing: 4px
}

.letter-spacing-5 {
    letter-spacing: 5px
}

.letter-spacing-6 {
    letter-spacing: 6px
}

.letter-spacing-7 {
    letter-spacing: 7px
}

.letter-spacing-8 {
    letter-spacing: 8px
}

.letter-spacing-9 {
    letter-spacing: 9px
}

.letter-spacing-10 {
    letter-spacing: 10px
}

.letter-spacing-minus-1 {
    letter-spacing: -1px
}

.letter-spacing-minus-2 {
    letter-spacing: -2px
}

.letter-spacing-minus-3 {
    letter-spacing: -3px
}

.letter-spacing-minus-4 {
    letter-spacing: -4px
}

.letter-spacing-minus-5 {
    letter-spacing: -5px
}

.letter-spacing-minus-6 {
    letter-spacing: -6px
}

.letter-spacing-minus-7 {
    letter-spacing: -7px
}

.letter-spacing-minus-8 {
    letter-spacing: -8px
}

.letter-spacing-minus-9 {
    letter-spacing: -9px
}

.letter-spacing-minus-10 {
    letter-spacing: -10px
}


/* font weight */

.font-weight-100 {
    font-weight: 100
}

.font-weight-200 {
    font-weight: 200
}

.font-weight-300 {
    font-weight: 300
}

.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.font-weight-800 {
    font-weight: 800
}

.font-weight-900 {
    font-weight: 900
}


/* text property */

.text-transform-unset {
    text-transform: unset
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.line-height-unset {
    line-height: unset
}

.line-height-normal {
    line-height: normal
}

.line-height-none {
    line-height: 0
}

.word-wrap {
    word-wrap: break-word
}

.text-nowrap {
    white-space: nowrap;
}

.text-transform-none {
    text-transform: none
}

.title-line-through {
    padding: 0 15px
}

.text-middle-line {
    position: relative;
    white-space: nowrap
}

.text-outside-line {
    position: relative
}

.text-outside-line::before {
    border-top: 1px solid;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -60px;
    top: 50%;
    width: 40px;
    opacity: .4;
}

.text-outside-line::after {
    border-top: 1px solid;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    left: -60px;
    top: 50%;
    width: 40px;
    opacity: .4;
}

.text-outside-line-left {
    position: relative
}

.text-outside-line-left::before {
    border-top: 1px solid;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    left: -100%;
    top: 50%;
    width: 90%;
    opacity: .2;
}

.text-leftside-line {
    position: relative;
    padding-left: 35px
}

.text-leftside-line:before {
    border-top: 1px solid;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    left: 0;
    top: 45%;
    width: 25px;
    opacity: .7
}

.text-outside-line-full {
    position: relative;
    display: inline-block
}

.text-outside-line-full::before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 99%;
    display: block;
    border-bottom: 1px solid;
    right: 100%;
    margin-right: 25px;
    opacity: .15
}

.text-outside-line-full::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 99%;
    display: block;
    border-bottom: 1px solid;
    left: 100%;
    margin-left: 25px;
    opacity: .15
}

.text-middle-line:before {
    border-bottom: 1px solid;
    position: absolute;
    content: "";
    width: 100%;
    top: 53%;
    opacity: 0.35
}

.text-bottom-line {
    width: 1px;
    border-top: 30px solid;
    margin-left: auto;
    margin-right: auto;
}

.text-middle-line-deep-pink:before {
    border-bottom: 1px solid #ff214f;
    position: absolute;
    content: "";
    width: 100%;
    margin-top: 5px;
    margin-left: -20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.text-decoration-line-through-black,
.text-decoration-line-through-deep-pink {
    position: relative;
}

.text-decoration-line-through-deep-pink:before {
    background: #ff214f;
}

.text-decoration-line-through-black:before {
    background: #000;
}

.text-decoration-line-through-black::before,
.text-decoration-line-through-deep-pink:before {
    content: "";
    height: 1px;
    left: -10px;
    position: absolute;
    top: 53%;
    width: calc(100% + 20px);
    -webkit-transform: translateY(-53%);
    -moz-transform: translateY(-53%);
    -ms-transform: translateY(-53%);
    -o-transform: translateY(-53%);
    transform: translateY(-53%);
}


/* ===================================
    Background color
====================================== */

.bg-transparent,
.background-transparent {
    background-color: transparent;
}

.bg-white,
.background-white {
    background-color: #fff;
}

.bg-black,
.background-black {
    background-color: #000;
}

.bg-extra-dark-gray {
    background-color: #1c1c1c;
}

.bg-dark-gray {
    background-color: #757575;
}

.bg-extra-medium-gray {
    background-color: #939393;
}

.bg-medium-gray {
    background-color: #dbdbdb;
}

i.fas.fa-angle-down.dropdown-toggle:before {
    display: none;
}

.bg-extra-light-gray {
    background-color: #e0e0e0
}

.bg-medium-light-gray {
    background-color: #ededed
}


/* .bg-light-gray {
    background-color: #f7f7f7
} */

.text-extra-dark-gray,
.btn.text-extra-dark-gray {
    color: #fff;
}

.bg-very-light-gray {
    background-color: #fafafa
}

.bg-deep-pink {
    background-color: #ff214f;
}

.bg-transparent-white {
    background-color: rgba(255, 255, 255, 0.3);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(37%, rgba(255, 255, 255, 0)), color-stop(96%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 96%, rgba(255, 255, 255, 1) 100%);
    /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1); */
}

.bg-transparent-black {
    background-color: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(37%, rgba(0, 0, 0, 0)), color-stop(96%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 1)));
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 1) 100%);
    background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 1) 100%);
    background: -ms-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 1) 96%, rgba(0, 0, 0, 1) 100%);
    /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1); */
}

.bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.85);
}

.bg-black-opacity {
    background-color: rgba(0, 0, 0, 0.85);
}

.bg-black-opacity-light {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-deep-pink-opacity {
    background-color: rgba(255, 33, 79, 0.85);
}

.bg-charcoal-gray {
    background-color: #0e0f10;
}


/* ===================================
    Custom
====================================== */

section {
    padding: 130px 0;
    overflow: hidden;
}

section.big-section {
    padding: 160px 0;
}

section.extra-big-section {
    padding: 200px 0;
}

section.half-section {
    padding: 80px 0;
}

label {
    margin-bottom: 5px;
    font-weight: 700;
}

.overlap-section {
    margin-top: -14%;
    position: relative
}

.col-2-nth .col-md-6:nth-child(2n+1) {
    clear: left;
}

.col-2-nth .col-sm-6:nth-child(2n+1) {
    clear: left;
}

.col-3-nth .col-md-4:nth-child(3n+1) {
    clear: left;
}

.col-3-nth .col-sm-4:nth-child(3n+1) {
    clear: left;
}

.col-4-nth .col-md-3:nth-child(4n+1) {
    clear: left;
}

.col-4-nth .col-sm-3:nth-child(4n+1) {
    clear: left;
}


/* input */

.input-border-bottom {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #484848;
    padding: 10px 0;
    margin-bottom: 30px;
    font-size: 14px;
    border-radius: 0;
}

.input-border-bottom:focus {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #929292;
}

.input-border-bottom::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 14px;
}

.input-border-bottom::-moz-placeholder {
    font-weight: 300;
    font-size: 14px;
}

.input-border-bottom:-ms-input-placeholder {
    font-weight: 300;
    font-size: 14px;
}

.extra-big-input,
.extra-big-textarea,
.extra-big-select select {
    padding: 18px 25px;
    font-size: 14px;
    line-height: 24px;
    height: 62px;
}

.big-input,
.big-textarea,
.big-select select {
    padding: 18px 25px;
    font-size: 14px;
    border-radius: 0;
}

.medium-input,
.medium-textarea,
.medium-select select {
    padding: 12px 20px;
    font-size: 14px;
    line-height: normal;
    border-radius: 0;
}

.small-input,
.small-textarea {
    padding: 12px 15px;
    font-size: 11px;
    line-height: normal
}

.small-select select {
    padding: 15px 15px;
    line-height: normal
}

.medium-input-light,
.medium-textarea-light,
.medium-select-light select {
    padding: 12px 25px;
    font-size: 12px;
    line-height: normal
}

.extra-small-input,
.extra-small-textarea,
.extra-small-select select {
    padding: 9px 17px;
    font-size: 12px;
    line-height: normal
}

.select-style {
    width: 100%;
    overflow: hidden;
    /* background: url("../images/select-arrow.png") no-repeat 97% 50%; */
    border: 1px solid #d1d1d1;
    margin-bottom: 20px
}

.select-style select {
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.select-style select:focus {
    outline: none;
    border: none;
    box-shadow: none
}

.input-group input,
.input-group textarea,
.input-group select {
    margin: 0;
    border-radius: 4px 0 0 4px;
    border-color: #fff;
    padding: 19px 25px;
}

.input-group-append .btn {
    border-radius: 0 4px 4px 0
}

.input-group-append .btn.btn-large {
    line-height: 2px;
    height: 62px;
    padding: 15px 25px !important
}

.input-group-404 input {
    height: 62px;
}

.input-group .required-error {
    border: 1px solid #ee2323 !important
}

.input-group .required-error,
.input-group .required-error+.input-group-btn button,
.input-group .required-error+.input-group-btn a.btn,
.input-group .required-error+.input-group-append>.btn {
    border: 1px solid #ee2323 !important
}

.input-border-white .input-border-bottom {
    border-bottom: 1px solid #ffffff;
    color: #fff;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important
}

.input-border-white .input-border-bottom:focus {
    background: transparent;
    border-bottom: 1px solid #ffffff;
    color: #fff
}

.input-border-white .input-border-bottom::-webkit-input-placeholder {
    color: #fff;
}

.input-border-white .input-border-bottom::-moz-placeholder {
    color: #fff;
}

.input-border-white .input-border-bottom:-ms-input-placeholder {
    color: #fff;
}

.btn .caret {
    border-top: 4px solid
}

input.input-bg {
    background-color: #f7f7f7;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px 20px;
    margin-bottom: 15px;
}

input.input-bg:focus,
textarea.input-bg:focus {
    border: 0;
    border: 1px solid rgba(0, 0, 0, .3);
}

textarea.input-bg {
    background-color: #f7f7f7;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 10px 20px;
    margin-bottom: 15px;
    min-height: 130px;
}

.input-bg::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
}

.input-bg::-moz-placeholder {
    font-weight: 400;
    font-size: 14px;
}

.input-bg:-ms-input-placeholder {
    font-weight: 400;
    font-size: 14px;
}

#success-subscribe-newsletter {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-subscribe-newsletter2 {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-contact-form {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-contact-form-2 {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-contact-form-3 {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-project-contact-form {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}

#success-project-contact-form-4 {
    clear: both;
    margin: 0 15px 15px;
    text-align: center;
    border: 1px solid #1a940a;
    color: #1a940a;
    padding: 2px 0;
    display: none;
    font-size: 11px
}


/* separator */

.separator-line-verticle-extra-small {
    width: 1px;
    height: 8px;
}

.separator-line-verticle-small-thick {
    width: 17px;
    height: 3px;
}

.separator-line-verticle-small {
    width: 1px;
    height: 13px;
}

.separator-line-verticle-small-thick2 {
    width: 64px;
    height: 7px;
}

.separator-line-verticle-large {
    width: 1px;
    height: 20px;
}

.separator-line-verticle-extra-large {
    width: 1px;
    height: 30px;
}

.separator-line-verticle-extra-large2 {
    width: 1px;
    height: 80px;
}

.separator-line-verticle-medium-thick-full {
    width: 8px;
    height: 40px;
}

.separator-line-verticle-large-thick {
    width: 109px;
    height: 11px
}

.separator-line-horrizontal-medium-light {
    width: 36px;
    height: 3px;
}

.separator-line-horrizontal-medium-light2 {
    width: 40%;
    height: 1px;
}

.separator-line-horrizontal-medium-light3 {
    width: 18%;
    height: 1px;
}

.separator-line-horrizontal-medium-thick {
    width: 50px;
    height: 5px;
}

.separator-line-horrizontal-full {
    width: 100%;
    height: 1px;
}


/* divider */

.divider-full {
    width: 100%;
    height: 1px;
    display: inline-block
}

.new-demo {
    background: #ff214f;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    margin-left: 8px;
    padding: 2px 8px;
}


/* opacity */

.opacity-very-light {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    top: 0;
    left: 0;
}

.opacity-light {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    top: 0;
    left: 0;
}

.opacity-extra-medium {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
}

.opacity-medium {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.75;
    top: 0;
    left: 0;
}

.opacity-full {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.8;
    top: 0;
    left: 0;
}

.opacity-full-dark {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.9;
    top: 0;
    left: 0;
}

.opacity1 {
    opacity: .1
}

.opacity2 {
    opacity: .2
}

.opacity3 {
    opacity: .3
}

.opacity4 {
    opacity: .4
}

.opacity5 {
    opacity: .5
}

.opacity6 {
    opacity: .6
}

.opacity7 {
    opacity: .7
}

.opacity8 {
    opacity: .8
}

.opacity9 {
    opacity: .9
}


/* box-shadow */

.btn-shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, .3);
}

.box-shadow-light {
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
}

.box-shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

.box-shadow-dark {
    box-shadow: 0 0 4px rgba(0, 0, 0, .4);
}

.box-shadow-large {
    box-shadow: 0 0 12px rgba(0, 0, 0, .1);
}


/* z-index */

.z-index-1111 {
    z-index: 1111;
}

.z-index-111 {
    z-index: 111;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.z-index-5 {
    z-index: 5;
}

.z-index-0 {
    z-index: 0;
}

.z-index-minus2 {
    z-index: -2;
}


/* verticle align */

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.vertical-middle {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0
}

.clear-both {
    clear: both
}

.line-break {
    display: block;
}

.no-transition *,
.swiper-container *,
.mfp-container *,
.skillbar-bar-main *,
.portfolio-grid *,
.parallax,
.rev-slider *,
.header-searchbar *,
.header-social-icon *,
.dropdropdown-menu * {
    transition-timing-function: none;
    -moz-transition-timing-function: none;
    -webkit-transition-timing-function: none;
    -o-transition-timing-function: none;
    -ms-transition-timing-function: none;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    -ms-transition-duration: 0s;
}

.absolute-middle-center {
    left: 50%;
    top: 50%;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
}

.absolute-bottom-center {
    left: 50%;
    top: 80%;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-80%);
    -moz-transform: translateX(-50%) translateY(-80%);
    -webkit-transform: translateX(-50%) translateY(-80%);
    transform: translateX(-50%) translateY(-80%);
    -o-transform: translateX(-50%) translateY(-80%);
}


/* ===================================
    Button
====================================== */

.formpage .btn {
    display: inline-block;
    border: 2px solid transparent;
    color: inherit;
    letter-spacing: .5px;
    line-height: inherit;
    border-radius: 0;
    width: auto;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
}

.formpage .btn-primary,
.formpage .btn-success,
.formpage .btn-info,
.btn-warning,
.btn-danger {
    color: #fff;
}


/* button size */

.formpage .btn.btn-very-small {
    font-size: 9px;
    padding: 1px 17px;
    line-height: 22px;
}

.formpage .btn.btn-small {
    font-size: 11px;
    padding: 4px 24px;
}

.formpage .btn.btn-medium {
    font-size: 12px;
    padding: 6px 25px 5px;
}

.formpage .btn.btn-large {
    font-size: 13px;
    padding: 9px 34px;
    line-height: 25px
}

.formpage .btn.btn-extra-large {
    font-size: 15px;
    padding: 12px 40px 13px;
    line-height: 25px
}

.formpage .btn-dual .btn {
    margin: 0 10px;
}

.formpage .btn i {
    margin-left: 6px;
    vertical-align: middle;
    position: relative;
    top: -1px
}


/* button background */

.formpage .btn.btn-white {
    background: #ffffff;
    border-color: #ffffff;
    color: #232323
}

.formpage .btn.btn-white:hover,
.formpage .btn.btn-white:focus {
    background: transparent;
    color: #fff
}

.btn.btn-black {
    background: #000000;
    border-color: #000000;
    color: #fff
}

.btn.btn-black:hover,
.btn.btn-black:focus {
    background: transparent;
    color: #000
}

.btn.btn-dark-gray {
    background: #232323;
    border-color: #232323;
    color: #fff
}

.btn.btn-dark-gray:hover,
.btn.btn-dark-gray:focus {
    background: transparent;
    color: #232323
}

.btn.btn-light-gray {
    background: #dbdbdb;
    border-color: #dbdbdb;
    color: #232323
}

.btn.btn-light-gray:hover,
.btn.btn-light-gray:focus {
    background: transparent;
    border-color: #dbdbdb;
    color: #dbdbdb
}

.btn.btn-deep-pink {
    background: #ff214f;
    border-color: #ff214f;
    color: #ffffff
}

.btn.btn-deep-pink:hover,
.btn.btn-deep-pink:focus {
    background: transparent;
    border-color: #ff214f;
    color: #ff214f
}

.btn-warning,
.btn-warning:hover {
    color: #fff;
}


/* button transparent */

.btn.btn-transparent-white {
    background: transparent;
    border-color: #ffffff;
    color: #ffffff
}

.btn.btn-transparent-white:hover,
.btn.btn-transparent-white:focus {
    background: #ffffff;
    border-color: #ffffff;
    color: #232323
}

.btn.btn-transparent-black {
    background: transparent;
    border-color: #000000;
    color: #000000
}

.btn.btn-transparent-black:hover,
.btn.btn-transparent-black:focus {
    background: #000000;
    border-color: #000000;
    color: #ffffff
}

.btn.btn-transparent-dark-gray {
    background: transparent;
    border-color: #232323;
    color: #232323
}

.btn.btn-transparent-dark-gray:hover,
.btn.btn-transparent-dark-gray:focus {
    background: #232323;
    border-color: #232323;
    color: #ffffff
}

.btn.btn-transparent-light-gray {
    background: transparent;
    border-color: #dbdbdb;
    color: #dbdbdb
}

.btn.btn-transparent-light-gray:hover,
.btn.btn-transparent-light-gray:focus {
    background: #dbdbdb;
    border-color: #dbdbdb;
    color: #232323
}

.btn.btn-transparent-deep-pink {
    background: transparent;
    border-color: #ff214f;
    color: #ff214f
}

.btn.btn-transparent-deep-pink:hover,
.btn.btn-transparent-deep-pink:focus {
    background: #ff214f;
    border-color: #ff214f;
    color: #fff
}


/* button rounded */

.btn.btn-rounded {
    border-radius: 50px
}

.btn.btn-rounded.btn-very-small {
    padding: 2px 23px 1px;
}

.btn.btn-rounded.btn-small {
    padding: 5px 29px;
}

.btn.btn-rounded.btn-medium {
    padding: 6px 32px;
}

.btn.btn-rounded.btn-large {
    padding: 9px 38px;
}

.btn.btn-rounded.btn-extra-large {
    padding: 12px 45px 13px;
}


/* image button */

.image-button {
    width: 100%;
    background: rgba(0, 0, 0, 0.80);
    padding: 26px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
}

.image-button:hover {
    background: rgba(0, 0, 0, 0.5);
}

.tag-cloud a {
    font-size: 10px;
    padding: 3px 8px;
    border: 1px solid #d9d9d9;
    margin: 0 8px 8px 0;
    display: inline-block;
    text-transform: uppercase;
    color: #232323;
    line-height: 18px;
}

.tag-cloud a:hover {
    background: #232323;
    color: #fff !important;
    border: 1px solid #232323;
}


/* dropdown style 1 */

.dropdown-style-1 .btn {
    color: #fff;
    font-size: 11px;
    line-height: normal;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-weight: normal
}

.dropdown-style-1 .btn.dropdown-toggle::after {
    vertical-align: middle;
    border-top: .4em solid;
    border-right: .4em solid transparent;
    border-bottom: 0;
    border-left: .4em solid transparent;
    margin-left: 0;
}

.dropdown-style-1 .btn:hover,
.custom-dropdown btn:focus {
    color: #ff214f;
}

.dropdown-style-1 .dropdown-menu {
    margin-top: 2px;
    min-width: 130px;
    border-radius: 0;
    border: none;
    z-index: 444;
    right: 0;
    left: auto !important;
    padding: 5px 0;
}

.dropdown-style-1 .dropdown-menu>li>a {
    padding: 6px 15px;
    font-size: 12px;
    display: block;
    line-height: normal;
}

.dropdown-style-1 .dropdown-menu>li>a:hover,
.dropdown-style-1 .dropdown-menu>li>a:focus {
    background: #ededed
}



@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.heder-menu-button {
    display: inline-block;
    line-height: 14px;
    padding-left: 5px;
    position: relative;
}


/* top: -1px; */

nav.navbar.bootsnav .heder-menu-button .navbar-toggler {
    float: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    top: 4px
}



.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-toggler .icon-bar+.icon-bar {
    margin-top: 4px;
}

.white-link .header-searchbar,
.white-link .header-social-icon {
    border-left: 1px solid rgba(255, 255, 255, .15);
}

.header-social-icon a {
    color: #232323
}

.header-social-icon a i,
.header-searchbar a i {
    margin: 0 11px;
}

.header-searchbar a i {
    position: relative;
}

.header-social-icon a:last-child i {
    margin-right: 0;
}

.search-icon {
    padding-right: 10px;
}


/* menu center */

.menu-center .header-right {
    flex: 1 2 auto;
}

.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
    min-height: 0px;
}


/* menu center logo */

.menu-logo-center .navbar-left>li {
    float: none;
    display: inline-block;
    vertical-align: middle;
}

header nav.menu-logo-center .navbar-nav>li>a {
    margin: 0 20px;
}

header nav.menu-logo-center .navbar-nav>li:last-child a {
    margin-right: 20px
}


/*default pionter cursor*/

header nav.navbar a:not([href]):not([tabindex]):focus,
header nav.navbar a:not([href]):not([tabindex]):hover {
    cursor: pointer;
}


/* header navigation color and logo */

header nav.navbar .navbar-nav>li>a,
nav.navbar.bootsnav ul.nav>li>a {
    color: #1b3272;
    cursor: pointer;
}

header nav.navbar.white-link .navbar-nav>li>a,
nav.navbar.bootsnav.white-link ul.nav>li>a,
header nav.navbar.white-link .header-social-icon a,
nav.navbar.bootsnav.white-link .header-social-icon a,
header nav.navbar.white-link .header-searchbar a,
nav.navbar.bootsnav.white-link .header-searchbar a,
header nav.navbar.bootsnav ul.nav.white-link>li>a,
nav.navbar.white-link .heder-menu-button a {
    color: #fff
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav>li>a,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav>li>a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a {
    color: #232323
}

header .logo .logo-dark,
header .logo .logo-light {
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

header .logo .logo-dark.default,
header .logo .logo-light.default {
    visibility: visible;
    opacity: 1;
    width: auto;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

header.sticky nav.header-dark-transparent .logo .logo-light,
header.sticky nav.header-dark .logo .logo-light {
    visibility: visible;
    opacity: 1;
    width: auto;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

header.sticky nav.header-dark-transparent .logo .logo-dark,
header.sticky nav.header-dark .logo .logo-dark {
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

header.sticky nav.header-light-transparent .logo .logo-dark,
header.sticky nav.header-light .logo .logo-dark {
    visibility: visible;
    opacity: 1;
    width: auto;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

header.sticky nav.header-light-transparent .logo .logo-light,
header.sticky nav.header-light .logo .logo-light {
    visibility: hidden;
    opacity: 0;
    width: 0;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

.navbar-top-scroll {
    position: absolute;
    top: 0;
    z-index: 5;
}

header.sticky nav.navbar-top-scroll {
    background-color: inherit;
}

header a.logo {
    display: inline-block;
    vertical-align: middle;
}


/* header a.logo img {
    max-height: 26px;
} */

header .left-nav .sidebar-part1 a.logo img {
    max-height: inherit;
}

.logo-holder img {
    max-height: 26px;
}


/* header animation */

.navbar-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}

.sticky .navbar-top {
    top: -160px;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}

.sticky.header-appear .navbar-top.navbar-transparent-no-sticky,
.sticky.header-appear .navbar-fixed-top.navbar-transparent-no-sticky {
    top: -160px;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}

.sticky.header-appear .navbar-top {
    top: 0;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}

header .bootsnav .dropdown .animated {
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
    -ms-animation-duration: 0.3s;
    -o-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu>li.dropdown>ul:before {
    position: absolute;
    height: calc(100% + 20px);
    width: 100%;
    display: block;
    content: "";
}

header.sticky .navbar-scroll-fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}

header.sticky .navbar-scroll-top {
    position: fixed;
    top: -120px;
    width: 100%;
    left: 0;
    transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
}

header.sticky.header-appear .navbar-scroll-top {
    top: 0;
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
}


/* navigation */

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav>li>a:hover,
header.header-appear nav.bootsnav.header-light .navbar-nav>li>a:hover,
header.header-appear nav.header-light-transparent .header-social-icon a:hover,
header.header-appear nav.header-light .header-social-icon a:hover,
header.header-appear nav.header-light.white-link .header-social-icon a:hover,
header.header-appear nav.header-light-transparent.white-link .header-social-icon a:hover,
header.header-appear nav.header-light-transparent.white-link .heder-menu-button a:hover,
header.header-appear nav.header-light-transparent .header-searchbar a:hover,
header.header-appear nav.header-light .header-searchbar a:hover,
header.header-appear nav.header-light.white-link .header-searchbar a:hover,
header.header-appear nav.header-light-transparent.white-link .header-searchbar a:hover,
header nav.navbar .navbar-nav>li>a:hover,
nav.navbar.bootsnav ul.nav>li>a:hover,
.header-search-form:hover,
.header-social-icon a:hover {
    color: rgba(0, 0, 0, 0.6)
}

header nav.navbar.white-link .navbar-nav>li>a:hover,
nav.navbar.bootsnav.white-link ul.nav>li>a:hover,
header nav.navbar.white-link .header-social-icon a:hover,
nav.navbar.bootsnav.white-link .header-social-icon a:hover,
header nav.navbar.white-link .header-searchbar a:hover,
nav.navbar.bootsnav.white-link .header-searchbar a:hover,
header nav.navbar.bootsnav ul.nav.white-link>li>a:hover,
header.header-appear nav.header-dark-transparent .navbar-nav>li>a:hover,
header.header-appear nav.header-dark .navbar-nav>li>a:hover,
header.sticky nav.header-dark .navbar-nav>li>a:hover,
header.sticky nav.header-dark-transparent .navbar-nav>li>a:hover,
header.header-appear nav.header-dark-transparent .search-button:hover,
header.header-appear nav.header-dark .search-button:hover,
header.sticky nav.header-dark .search-button:hover,
header.sticky nav.header-dark-transparent .search-button:hover {
    color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav>li>a:hover,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-searchbar a:hover,
header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .header-social-icon a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav>li>a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-searchbar a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-social-icon a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover,
header.sticky nav.navbar-fixed-top.header-light.white-link .header-menu-button a:hover {
    color: rgba(0, 0, 0, 0.6)
}

.navbar-nav>li>a {
    line-height: 20px;
}

header.sticky nav.navbar.white-link .navbar-nav>li>a:hover,
header.sticky nav.navbar.bootsnav.white-link ul.nav>li>a:hover {
    color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav>li>a:hover,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav>li>a:hover,
header.sticky nav.header-dark .header-social-icon a:hover {
    color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav>li.active>a,
header.header-appear nav.bootsnav.header-light .navbar-nav>li.active>a,
header nav.navbar .navbar-nav>li.active>a,
nav.navbar.bootsnav ul.nav>li.active>a {
    color: rgba(0, 0, 0, 0.6)
}

header nav.navbar.white-link .navbar-nav>li.active>a,
nav.navbar.bootsnav.white-link ul.nav>li.active>a,
header nav.navbar.bootsnav ul.nav.white-link>li.active>a,
header.header-appear nav.header-dark-transparent .navbar-nav>li.active>a,
header.header-appear nav.header-dark .navbar-nav>li.active>a,
header.sticky nav.header-dark .navbar-nav>li.active>a,
header.sticky nav.header-dark-transparent .navbar-nav>li.active>a,
header.sticky nav.navbar.bootsnav.header-dark-transparent.white-link .navbar-nav>li.active>a {
    color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav>li.active>a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav>li.active>a {
    color: rgba(0, 0, 0, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav>li.active>a,
header.sticky nav.navbar.bootsnav.white-link ul.nav>li.active>a {
    color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav>li.active>a,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav>li.active>a {
    color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav>li>a.active,
header.header-appear nav.bootsnav.header-light .navbar-nav>li>a.active,
header nav.navbar .navbar-nav>li>a.active,
nav.navbar.bootsnav ul.nav>li>a.active {
    color: rgba(0, 0, 0, 0.6)
}

header nav.navbar.white-link .navbar-nav>li>a.active,
nav.navbar.bootsnav.white-link ul.nav>li>a.active,
header nav.navbar.bootsnav ul.nav.white-link>li>a.active,
header.header-appear nav.header-dark-transparent .navbar-nav>li>a.active,
header.header-appear nav.header-dark .navbar-nav>li>a.active,
header.sticky nav.header-dark .navbar-nav>li>a.active,
header.sticky nav.header-dark-transparent .navbar-nav>li>a.active {
    color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav>li>a.active,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav>li>a.active {
    color: rgba(0, 0, 0, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav>li>a.active,
header.sticky nav.navbar.bootsnav.white-link ul.nav>li>a.active {
    color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav>li>a.active,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav>li>a.active {
    color: rgba(255, 255, 255, 0.6);
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav>li.dropdown.on>a,
header.header-appear nav.bootsnav.header-light .navbar-nav>li.dropdown.on>a,
header nav.navbar .navbar-nav>li.dropdown.on>a,
nav.navbar.bootsnav ul.nav>li.dropdown.on>a {
    color: rgba(0, 0, 0, 0.6)
}

header nav.navbar.white-link .navbar-nav>li.dropdown.on>a,
nav.navbar.bootsnav.white-link ul.nav>li.dropdown.on>a,
header nav.navbar.bootsnav ul.nav.white-link>li.dropdown.on>a,
header.header-appear nav.header-dark-transparent .navbar-nav>li.dropdown.on>a,
header.header-appear nav.header-dark .navbar-nav>li.dropdown.on>a,
header.sticky nav.header-dark .navbar-nav>li.dropdown.on>a,
header.sticky nav.header-dark-transparent .navbar-nav>li.dropdown.on>a {
    color: rgba(255, 255, 255, 0.6)
}

header.sticky nav.navbar-fixed-top.header-light-transparent.white-link .navbar-nav>li.dropdown.on>a,
header.sticky nav.navbar-fixed-top.header-light.white-link .navbar-nav>li.dropdown.on>a {
    color: rgba(0, 0, 0, 0.6)
}

header.sticky nav.navbar.white-link .navbar-nav>li.dropdown.on>a,
header.sticky nav.navbar.bootsnav.white-link ul.nav>li.dropdown.on>a {
    color: rgba(0, 0, 0, 0.6);
}

header.sticky nav.navbar.header-dark.white-link .navbar-nav>li.dropdown.on>a,
header.sticky nav.navbar.bootsnav.header-dark.white-link ul.nav>li.dropdown.on>a {
    color: rgba(255, 255, 255, 0.6);
}

header {
    width: 100%;
    z-index: 99;
}

.header-with-topbar .top-header-area {
    font-size: 11px;
    position: fixed;
    top: 0;
    z-index: 10005;
    width: 100%;
    transition: ease-in-out 0.3s;
    -webkit-transition: ease-in-out 0.3s;
    -moz-transition: ease-in-out 0.3s;
    -ms-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
}

.sticky.header-with-topbar .top-header-area {
    top: -45px
}

.sticky.header-with-topbar.fixed-topbar .top-header-area {
    top: 0
}


/*.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top, .sticky.header-with-topbar.fixed-topbar nav.navbar.bootsnav.navbar-fixed-top, .sticky.header-with-topbar.header-appear.fixed-topbar nav.navbar.bootsnav.navbar-top {top: 36px;}*/

.navbar-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.navbar-form.search-box {
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;
}

header .top-header-area .icon-social-very-small a:last-child i {
    margin-right: 0;
}

header nav .row {
    height: auto;
    padding: 0;
}


/*header nav .row > div:first-child { flex: 1 1 auto;}*/

header nav.navbar {
    border-radius: 0;
    padding: 0;
}

header nav.navbar-default,
nav.navbar.bootsnav {
    border-bottom: 0;
}

header nav {
    width: 100%;
    top: 0px;
    z-index: 99;
    margin-bottom: 0;
}

header nav .nav-header-container {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

header nav .navbar-nav>li>a {
    color: #232323;
    font-size: 12px;
    font-weight: 800;
    margin: 0 20px;
    padding: 5px 0px 5px;
    letter-spacing: 0.015em;
}

header nav .navbar-nav>li>a:hover,
header nav .navbar-nav>li>a:focus,
header nav .navbar-nav>li.active>a {
    background: transparent;
}

header nav .navbar-nav>li:last-child a {
    margin-right: 0;
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    z-index: 9999
}

header nav.navbar-default .navbar-nav>li>a:hover,
header nav.navbar-default .navbar-nav>li>a:focus,
header nav.navbar-default .navbar-nav>li.active>a {
    border-bottom-color: #232323
}

header nav .navbar-nav.text-normal>li>a {
    font-weight: 600;
    font-size: 12px
}

.navbar-nav>li {
    position: inherit;
    margin-top: 10px;
}


/* .navbar-nav>li.dropdown>.fas {
    display: none
} */

.navbar-nav>li ul {
    margin: 0;
    padding: 0;
}

.navbar-toggler {
    border-radius: 0;
    margin-right: 0;
    float: right;
    border: none;
}

.mobile-toggle {
    display: block
}

nav.navbar.bootsnav .navbar-toggle {
    top: 2px;
}

.sidebar-nav .mobile-toggle {
    display: none
}

.mobile-toggle span {
    display: block;
    width: 16px;
    height: 2px;
    background: #232323;
    content: "";
}

.mobile-toggle span:nth-child(2) {
    margin: 3px 0px;
}

.mobile-toggle:hover span,
.white-link .mobile-toggle:hover span {
    background-color: red
}

.white-link .mobile-toggle span {
    background: #fff;
}

header nav .brand-logo {
    padding-top: 20px;
    padding-bottom: 20px;
}

header.sticky nav .brand-logo {
    padding-top: 15px;
    padding-bottom: 15px;
}

header.sticky nav.navbar.navbar-default.navbar-fixed-top ul.nav>li>a,
header.sticky nav.navbar.navbar-default.navbar-top ul.nav>li>a {
    padding: 20px 0 20px;
}


/* mega menu */

nav .accordion-menu {
    position: initial;
}

nav.navbar.bootsnav li.dropdown ul li {
    list-style: none;
    padding: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full {
    width: 100%;
    box-sizing: border-box;
    background: rgba(35, 35, 35, 1);
    padding: 45px 50px;
    box-shadow: none;
    border-radius: 0;
    border: 0;
    margin-top: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full ul {
    margin: 0;
    list-style: none;
}

nav.navbar.bootsnav li.dropdown .menu-back-div>ul {
    margin: 0 auto;
    width: 1070px;
    display: flex;
    flex-wrap: wrap;
    background: rgba(35, 35, 35, 1);
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul li.dropdown-header {
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: inherit;
    padding: 0 40px;
    border-bottom: none;
    text-transform: uppercase;
    line-height: 1.42857143;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul li a {
    text-align: left;
    font-size: 12px;
    color: #9d9d9d;
    padding: 3px 40px;
    text-transform: capitalize;
    margin: 0;
    display: block;
    position: relative;
    overflow: hidden;
    left: 0
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul li.mega-menu-column:first-child a,
nav.navbar.bootsnav li.dropdown .mega-menu-full>ul li.mega-menu-column:first-child .dropdown-header {
    padding-left: 0;
}

nav.navbar.bootsnav li.dropdown.megamenu-fw .icon-list-menu li>a>i {
    font-size: 14px;
    vertical-align: middle;
    width: 24px;
    display: inline-block;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul>li {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul>li:last-child {
    border: 0;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul>li:last-child img {
    padding-left: 40px;
    background-size: cover;
    background-position: bottom right;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul>li>ul {
    margin-top: 18px;
}

nav.navbar.bootsnav li.dropdown .mega-menu-full>ul>li>ul:first-child {
    margin-top: 0;
}

nav.navbar.bootsnav li.dropdown .megamenu-fw .icon-list-menu li>a>i {
    font-size: 14px;
    vertical-align: middle;
    width: 24px;
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li>a.menu-banner-image,
nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li>a:hover.menu-banner-image {
    left: 0;
    padding: 0;
    margin-bottom: 20px
}

nav.navbar.navbar-default ul li.dropdown .dropdown-menu .mega-menu-column li:last-child>a.menu-banner-image {
    margin-bottom: 0;
}


/* header center logo */

.nav-left,
.nav-right,
.nav-center {
    display: table-cell;
    vertical-align: middle;
    width: 33.33%
}

header nav .justify-content-center .navbar-nav>li:last-child a {
    margin-right: 20px;
}

.social-icon a {
    margin: 0 15px;
}

.social-icon a:first-child {
    margin-left: 0;
}

.social-icon a:last-child {
    margin-right: 0;
}

header nav.nav-center-logo .row {
    min-height: 0;
    height: auto
}

header nav.nav-center-logo .row {
    display: block;
}

header nav.nav-center-logo .row>div:first-child {
    flex: none;
}

.nav-center-logo .navbar-nav>li {
    float: none;
    display: inline-block;
    vertical-align: middle;
}

.nav-center-logo .navbar-nav>li>a {
    display: flex
}

.nav-center-logo .navbar-left {
    margin-left: -15px;
}

.nav-center-logo .navbar-left li:first-child a {
    margin-left: 0;
}

nav.nav-center-logo.navbar.bootsnav li.dropdown ul.dropdown-menu,
nav.bootsnav.brand-center ul.nav>li.dropdown>ul.dropdown-menu {
    margin-top: 0px;
}

.center-logo {
    left: 50%;
    position: absolute;
    margin-top: -14px;
    top: 50%;
    text-align: center;
    transform: translateX(-50%);
    z-index: 1;
    max-width: 20%
}

nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
    text-align: left
}

header nav.navbar-fixed .nav-header-container {
    width: 100%;
    float: left;
}

header .menu-logo-center .navbar-nav>li.social-links {
    padding: 26px 0;
}

header.sticky .menu-logo-center .navbar-nav>li.social-links {
    padding: 20px 0;
}


/* top logo */

.navbar.navbar-brand-top>.container .navbar-brand,
.navbar>.container-fluid .navbar-brand {
    margin-left: 0;
}

.navbar-brand-top .navbar-brand {
    height: auto;
}

nav.navbar-brand-top.navbar.navbar-default ul.nav>li>a {
    padding: 20px 0;
}

.navbar-brand-top .navbar-collapse.collapse {
    flex-grow: inherit;
}


/* center navigation */

.center-nav {
    float: none;
    text-align: center
}

.center-nav>li {
    float: none;
    display: inline-block;
}

nav.navbar.bootsnav.menu-center ul.nav.navbar-center {
    display: table;
    float: none;
    margin: 0 auto;
    table-layout: fixed;
}


/* simple dropdown menu */

.simple-dropdown.dropdown {
    position: relative;
}

.simple-dropdown .dropdown-menu {
    position: absolute;
    left: 0;
    background: #1b3272;
    min-width: 220px;
    padding: 13px 0 15px;
    box-shadow: none;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu>li {
    padding-left: 28px;
    padding-right: 28px;
}

.simple-dropdown .dropdown-menu>li>a {
    font-size: 12px;
    color: #ededed;
}

.simple-dropdown .dropdown-menu>li a {
    padding: 9px 0 7px;
    overflow: hidden;
    position: relative;
    left: 0;
    font-weight: 500;
    display: block;
    line-height: 1.42857143;
}

.simple-dropdown .dropdown-menu>li a:hover {
    
    color: #fff;
}

.simple-dropdown .dropdown-menu>li>a.dropdown-toggle .fas,
nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li.dropdown>a>.fas {
    position: absolute;
    right: 0;
    font-size: 12px;
    margin-top: 0;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

nav.navbar.bootsnav.sidebar-nav .nav.navbar-left-sidebar li a .fas {
    font-size: 16px;
}

nav.navbar.bootsnav .simple-dropdown .dropdown-menu>li.dropdown>ul,
nav.navbar.bootsnav .simple-dropdown .dropdown-menu>li.dropdown>ul>li.dropdown>ul {
    background: rgba(35, 35, 35, 1);
    display: none;
    left: 220px;
    margin: -13px 0 0 1px;
    min-width: 220px;
    white-space: nowrap;
    position: absolute;
    top: 0;
    padding: 13px 0;
    font-weight: 500;
    text-transform: capitalize;
}

.simple-dropdown .dropdown-menu>li.dropdown>ul li a {
    color: #9d9d9d;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

nav.navbar.bootsnav.navbar-default li.dropdown.simple-dropdown>ul.dropdown-menu>li.active>a {
    color: #fff;
}


/* light transparent navigation */

header.header-appear nav.header-light-transparent,
header.sticky nav.navbar-fixed-top.header-light-transparent,
header.sticky nav.navbar-scroll-fixed-top.header-light-transparent {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6)
}

header.header-appear nav.header-light-transparent .separator-line-verticle-small,
header.header-appear nav.header-light .separator-line-verticle-small {
    background: rgba(0, 0, 0, 0.4)
}

header.header-appear nav.header-light-transparent .header-social-icon a,
header.header-appear nav.header-light .header-social-icon a,
header.header-appear nav.header-light.white-link .header-social-icon a,
header.header-appear nav.header-light-transparent.white-link .header-social-icon a,
header.header-appear nav.header-light-transparent.white-link .heder-menu-button a,
header.header-appear nav.header-light-transparent .header-searchbar a,
header.header-appear nav.header-light .header-searchbar a,
header.header-appear nav.header-light.white-link .header-searchbar a,
header.header-appear nav.header-light-transparent.white-link .header-searchbar a {
    color: #232323
}

header.header-appear nav.bootsnav.header-light-transparent .navbar-nav>li>a,
header.header-appear nav.bootsnav.header-light .navbar-nav>li>a {
    color: #232323;
}

header.header-appear nav.header-light-transparent .header-searchbar,
header.header-appear.header-light-transparent .header-social-icon,
header.header-appear nav.header-light .header-searchbar,
header.header-appear nav.header-light-transparent.white-link .header-social-icon,
header.sticky nav.header-light-transparent .header-searchbar,
header.sticky nav.header-light-transparent .header-social-icon,
header.sticky nav.header-light .header-searchbar,
header.sticky nav.header-light .header-social-icon {
    border-left: 1px solid rgba(0, 0, 0, 0.25);
}

header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .navbar-nav>li>a,
header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-social-icon a,
header.header-appear nav.nav-fixed-top.header-light-transparent.white-link .header-searchbar a,
header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .navbar-nav>li>a,
header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .header-social-icon a,
header.header-appear nav.nav-scroll-fixed-top.header-light-transparent.white-link .header-searchbar a {
    color: #232323
}

header.sticky nav.header-light-transparent .heder-menu-button .mobile-toggle span,
header.sticky nav.header-light .heder-menu-button .mobile-toggle span {
    background: #232323;
}


/* dark transparent navigation */

header.header-appear nav.header-dark-transparent,
header.sticky nav.navbar-fixed-top.header-dark-transparent,
header.sticky nav.navbar-scroll-fixed-top.header-dark-transparent {
    background: rgba(23, 23, 23, 0.85);
}

header.header-appear nav.header-dark-transparent .separator-line-verticle-small,
header.header-appear nav.header-dark .separator-line-verticle-small {
    background: #ffffff
}

header.header-appear nav.header-dark-transparent .navbar-nav>li>a,
header.header-appear nav.header-dark .navbar-nav>li>a,
header.sticky nav.header-dark .navbar-nav>li>a,
header.sticky nav.header-dark-transparent .navbar-nav>li>a {
    color: #ffffff
}

header.header-appear nav.header-dark-transparent .header-social-icon a,
header.header-appear nav.header-dark .header-social-icon a,
header.sticky nav.header-dark .header-social-icon a,
header.sticky nav.header-dark-transparent .header-social-icon a {
    color: #ffffff
}

header.header-appear nav.header-dark-transparent .search-button,
header.header-appear nav.header-dark-transparent .right-menu-button,
header.header-appear nav.header-dark .search-button,
header.header-appear nav.header-dark .right-menu-button,
header.sticky nav.header-dark .search-button,
header.sticky nav.header-dark .right-menu-button,
header.sticky nav.header-dark-transparent .search-button,
header.sticky nav.header-dark-transparent .right-menu-button {
    color: #ffffff
}

header.sticky nav.header-dark-transparent .header-social-icon,
header.sticky nav.header-dark .header-social-icon,
header.sticky nav.header-dark-transparent .header-searchbar,
header.sticky nav.header-dark .header-searchbar {
    border-left: 1px solid rgba(255, 255, 255, 0.4);
}

header.sticky nav.header-dark-transparent .mobile-toggle span,
header.sticky .header-dark .mobile-toggle span {
    background: #fff;
}

header.sticky nav.header-dark-transparent .mobile-toggle:hover span,
header.sticky .header-dark .mobile-toggle:hover span {
    background: red;
}


/* light navigation */

header.header-appear nav.header-light,
header.sticky nav.navbar-fixed-top.header-light,
header.sticky nav.navbar-scroll-fixed-top.header-light {
    background-color: #ffffff;
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6)
}


/* dark navigation */

header.header-appear nav.header-dark,
header.sticky nav.navbar-fixed-top.header-dark,
header.sticky nav.navbar-scroll-fixed-top.header-dark {
    background-color: #000000;
}


/* collepsed menu icon */

.navbar-default .navbar-toggler .icon-bar,
header.sticky .navbar-default.header-light .navbar-toggler .icon-bar,
header.sticky .navbar-default.header-light-transparent .navbar-toggler .icon-bar {
    background: #232323;
}

.navbar-default.white-link .navbar-toggler .icon-bar,
header.sticky .navbar-default.header-dark .navbar-toggler .icon-bar,
header.sticky .navbar-default.header-dark-transparent .navbar-toggler .icon-bar {
    background: #fff;
}


/* hamburger menu */

nav.navbar.bootsnav .btn-hamburger {
    padding: 0;
}

nav.navbar.bootsnav .btn-hamburger .navbar-toggler {
    margin: 0;
    top: -2px;
    padding: 0;
}

.hamburger-menu-wrepper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -10005;
    padding: 55px 80px;
    display: table;
    opacity: 0;
    visibility: collapse;
}

.menu-middle {
    display: table;
    height: 100%;
    width: 65%;
    margin: 0 auto;
    opacity: 0;
}

.hamburger-menu-links {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 52px;
    line-height: 55px;
    opacity: 0;
}

.hamburger-menu-links li {
    float: left;
    margin-bottom: 36px;
    width: 50%;
}

.hamburger-menu-links li a {
    color: #fff;
    position: relative;
}

.hamburger-menu-links li.show>a {
    color: #ff2149
}

.hamburger-menu-links li a:hover,
.follow-box a:hover {
    color: #ff2149
}

.animation-box {
    position: absolute;
    top: 5%;
    height: 100%;
    width: 100%;
    left: 0;
    transform: perspective(600px) rotateX(-20deg);
    -weblit-transform: perspective(600px) rotateX(-20deg);
    -moz-transform: perspective(600px) rotateX(-20deg);
    -ms-transform: perspective(600px) rotateX(-20deg);
    -o-transform: perspective(600px) rotateX(-20deg);
}

.hamburger-menu.show-menu .hamburger-menu-wrepper {
    z-index: 10004;
    opacity: 1;
    visibility: visible;
}

.hamburger-menu.show-menu .animation-box {
    top: 0;
    transform: perspective(600px) rotateX(0deg);
    -webkit-transform: perspective(600px) rotateX(0deg);
    -moz-transform: perspective(600px) rotateX(0deg);
    -o-transform: perspective(600px) rotateX(0deg);
    -ms-transform: perspective(600px) rotateX(0deg);
    opacity: 1;
}

.hamburger-menu.show-menu .hamburger-menu-links,
.hamburger-menu.show-menu .menu-middle {
    opacity: 1;
}

.hamburger-menu.show-menu .btn-hamburger .mobile-toggle,
.hamburger-menu .btn-hamburger .close-menu {
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
}

.hamburger-menu.show-menu .btn-hamburger .close-menu,
.hamburger-menu .btn-hamburger .mobile-toggle {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
}

.hamburger-menu .hamburger-menu-wrepper .btn-hamburger {
    border-color: #fff;
    height: 18px;
    position: absolute;
    width: 18px;
    z-index: 99;
    padding: 0;
    margin: -27px 0 0 -15px;
    opacity: 0;
}

.hamburger-menu.show-menu .hamburger-menu-wrepper .btn-hamburger {
    opacity: 1;
    right: 40px;
    transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -ms-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
}

.hamburger-menu .close-button-menu {
    float: none;
    height: auto;
    margin: 0;
    padding: 0;
    position: inherit;
    right: -26px;
    top: 44px;
    width: auto;
}

.hamburger-menu .close-button-menu:after,
.hamburger-menu .close-button-menu:before {
    background: #fff;
    width: 22px;
    right: 26px;
    top: -34px;
}

.hamburger-menu-links li.dropdown>a:after {
    content: "\f107";
    display: inline-block;
    position: absolute;
    right: -60px;
    top: 15px;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    font-size: 42px
}


/* header style two */

.header-with-topbar .navbar-top,
.header-with-topbar .navbar-fixed-top {
    top: 36px;
    background: #fff;
    box-shadow: 0 4px 10px -10px rgba(0, 0, 0, 0.6)
}

.header-with-topbar.sticky .navbar-top {
    top: -160px;
}

.header-with-topbar.sticky.header-appear .navbar-top {
    top: 0;
}


/* full width light transparent navigation */

header nav.nav-full-width {
    width: 100%
}

.close-button-menu {
    float: right;
    padding: 10px;
    background-color: transparent;
    color: #232323;
    border: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    outline: none;
    top: 20px;
    right: 20px
}



.full-width-pull-menu span.dropdown-toggle {
    position: absolute;
    right: 30px;
    width: 40px;
    height: 40px;
    text-align: center;
    top: 43px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}





.full-width-pull-menu .dropdown ul {
    background-color: inherit;
}


/* full width navigation style 1 */

.full-width-pull-menu .link-style-1 .link-style-1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.full-width-pull-menu .link-style-1 ul li {
    margin: 20px 0;
    display: table
}

.full-width-pull-menu .link-style-1 ul li a {
    background: inherit;
    color: #232323;
    font-size: 22px;
    line-height: 26px;
    position: relative;
    padding-left: 35px;
}

.full-width-pull-menu .link-style-1 ul li:hover>a,
.full-width-pull-menu .link-style-1 ul li.show>a {
    color: #ff214f
}

.full-width-pull-menu .link-style-1 ul li:hover>a:after,
.full-width-pull-menu .link-style-1 ul li.show>a:after {
    background: #ff214f
}

.full-width-pull-menu .link-style-1 ul li a:after {
    background: #dbdbdb;
    content: "";
    display: inline-block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 14px;
    width: 18px;
}

.full-width-pull-menu .link-style-1 .dropdown.show ul {
    display: block;
}

.full-width-pull-menu .link-style-1 .dropdown ul {
    position: inherit;
    left: 0;
    right: 0;
    padding: 10px 30px 0;
    background-color: inherit;
    border: 0;
    float: none;
    box-shadow: none;
    margin: 0;
}

.full-width-pull-menu .link-style-1 ul li li a {
    font-size: 18px;
    line-height: 22px;
    padding-left: 30px;
}

.full-width-pull-menu .link-style-1 .dropdown ul li a:hover,
.dropdown ul li a:focus {
    background-color: inherit
}

.full-width-pull-menu .link-style-1 .dropdown ul li {
    margin: 5px;
}

.full-width-pull-menu .link-style-1 .dropdown ul li:last-child {
    margin-bottom: 0;
}


/* full navigation */

.show-menu .menu-wrap,
.menu-wrap {
    left: auto
}

.show-menu .menu-wrap {
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    opacity: 1;
    visibility: visible;
    z-index: 10001;
}

.menu-wrap {
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    width: 100%;
    z-index: -10001;
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
}


nav.navbar ul.nav .dropdown-menu.second-level .dropdown-menu {
    margin-top: 0
}

.navbar-default ul li li.dropdown ul {
    display: none
}

.navbar-default ul li li.dropdown.on>ul {
    display: block;
    position: absolute;
    left: 100%;
    margin-top: 0;
    padding: 0;
    top: 0;
}

.navbar-default ul li li.dropdown.on>ul>li.dropdown.on>ul {
    display: block;
    position: absolute;
    left: 100%;
    margin-top: 0;
    padding: 0;
    top: 0;
}

.navbar-default ul li li.dropdown ul.dropdown-menu {
    position: absolute;
    left: 100%;
    margin-top: 0;
    padding: 0;
    top: 0;
    box-shadow: none;
}

.navbar-default ul li li.dropdown ul.dropdown-menu li {
    padding: 0;
}

.dropdown-menu .dropdown a {
    position: relative
}


/* navigation left sidebar */

header .menu-wrap .widget {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%
}

.nav.navbar-left-sidebar {
    display: block;
    transition: all 0s ease-in-out 0s;
    -webkit-transition: all 0s ease-in-out 0s;
    -moz-transition: all 0s ease-in-out 0s;
    -ms-transition: all 0s ease-in-out 0s;
    -o-transition: all 0s ease-in-out 0s;
    overflow-y: auto;
}

.left-nav-on .sidebar-part2 .sidebar-middle .sidebar-middle-menu .nav {
    overflow-x: hidden;
    display: block;
}

.nav.navbar-left-sidebar li {
    display: inline-block;
    width: 100%;
    padding: 0 45px;
}

.nav.navbar-left-sidebar li a,
nav.navbar.bootsnav.navbar-left-sidebar ul.nav>li>a {
    font-size: 11px;
    text-transform: uppercase;
    color: #939393;
    background: transparent !important;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5 !important;
    padding: 15px 0;
    margin: 0;
}

nav.navbar.sidebar-nav .navbar-left-sidebar li.dropdown .third-level a>[class^="ti-"],
nav.navbar.sidebar-nav .navbar-left-sidebar li.dropdown .third-level a>[class*=" ti-"] {
    margin-right: 5px;
}

.nav.navbar-left-sidebar li:last-child a {
    border-bottom: none
}

.nav.navbar-left-sidebar li a:hover {
    color: #fff
}

.nav.navbar-left-sidebar li a .nav-caret {
    height: 35px;
    text-align: right;
    width: 35px;
}

.nav.navbar-left-sidebar .dropdown .second-level {
    left: 290px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    /* display: block !important; visibility: hidden;*/
}


.bottom-menu-icon a {
    font-size: 24px;
    width: 23px;
    height: 18px;
    display: inline-block;
    position: relative;
}

header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active>a,
header .sidebar-part2 nav.navbar.bootsnav ul.second-level li.active ul li.active>a {
    color: #ff214f
}

.sidebar-part2 .navbar-collapse.collapse {
    overflow-y: auto !important;
}


.formpage .nav-icon.active span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.formpage .nav-icon.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.formpage .nav-icon.active span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
}

.formpage nav.navbar.brand-center ul.nav>li>a {
    padding: 26px;
    margin: 0;
}

.formpage header.sticky nav.navbar.brand-center ul.nav>li>a {
    padding: 20px;
    margin: 0 20px;
}

.formpage .sidebar-middle ::-webkit-scrollbar,
.formpage .sidebar-nav-style-1 ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
}

.formpage .sidebar-middle ::-webkit-scrollbar-thumb,
.formpage .sidebar-nav-style-1 ::-webkit-scrollbar-thumb {
    background-color: #9e9f9f;
}

.formpage .navbar-collapse,
.formpage .sub-menu {
    -webkit-overflow-scrolling: auto;
    -moz--webkit-overflow-scrolling: auto;
    -ms--webkit-overflow-scrolling: auto;
    -o--webkit-overflow-scrolling: auto;
}

.formpage .left-menu-inner {
    overflow-y: auto;
    overflow-x: hidden;
}

.formpage .nav.navbar-left-sidebar>li>ul li a {
    display: block;
    font-weight: 500;
}




/* square pagination */


.formpage .wow.fadeIn.w40 {
    width: 30%;
    margin: 0 auto;
    background: #fff;
    padding: 80px 50px;
    border-radius: 15px;
    box-shadow: 0 0 0 #ddd;
}
.formpage form a, .formpage form a:hover{
    color: #232323 !important;
}
.formpage form button{
    font-size: 15px;
    padding: 4px 24px;
    background: #1b3272;
    border-color: #1b3272;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 5px;
    cursor: pointer;
}
.formpage form button:hover{
    background: #fff;
    color:#1b3272 ;
}
.formpage .wow.fadeIn.w40 h1 {
    font-size: 38px;
    text-align: center;
    color: #1b3272 !important;
    font-weight: 500;
    font-family: oswald,san-sarif;
    margin-bottom: 40px;
}
.formpage footer a:hover{
color:gray !important;
}

/* home - portfolio  parallax */

.formpage .parallax-title {
    padding: 16px 35px;
    display: inline-block
}


/* home - blog grid */

.formpage .highlight-bg-text {
    padding: 5px 16px;
    display: inline-block;
    margin-bottom: 15px;
}


/* ===================================
   Others
====================================== */


/* services modern */

.formpage .arrow-bottom {
    position: relative;
}

.formpage .arrow-bottom:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 245, 245, 0);
    border-top-color: #f5f5f5;
    border-width: 15px;
    margin-left: -15px;
    z-index: 9;
}

.formpage .arrow-top {
    position: relative;
}

.formpage .arrow-top:after {
    top: -29px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(245, 245, 245, 0);
    border-bottom-color: #f5f5f5;
    border-width: 15px;
    margin-left: -15px;
    z-index: 9;
}




/* animation */

@-webkit-keyframes up-down-animation {
    0% {
        opacity: 1;
        top: 0px;
    }
    25% {
        opacity: .4;
        top: 2px;
    }
    50% {
        opacity: .3;
        top: 4px;
    }
    75% {
        opacity: .2;
        top: 5px;
    }
    100% {
        opacity: 0;
        top: 9px;
    }
}

@keyframes up-down-animation {
    0% {
        opacity: 1;
        top: 0px;
    }
    25% {
        opacity: .4;
        top: 4px;
    }
    50% {
        opacity: .3;
        top: 8px;
    }
    75% {
        opacity: .2;
        top: 12px;
    }
    100% {
        opacity: 0;
        top: 16px;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .formpage nav .accordion-menu {
        position: static;
    }
    .formpage .swiper-container,
    .formpage .blog-post-content {
        width: 100%;
    }
    .formpage .left-nav .sidebar-part2 .navbar-expand-lg .navbar-nav {
        flex-direction: column;
    }
    .formpage .sidebar-nav-style-1.navbar-expand-lg .navbar-collapse {
        display: block !important;
    }
    .formpage .signature {
        height: auto;
    }
}


/* ===================================
   demo page css
====================================== */

.formpage .demo-heading {
    font-size: 40px;
    line-height: 46px;
}

.formpage .buy-theme {
    transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    position: fixed;
    top: 130px;
    right: -70px;
    background: #fff;
    z-index: 1000;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999
}

.formpage .formpage .buy-theme i {
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    color: #6f6f6f
}

.formpage .all-demo i {
    font-size: 15px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    color: #6f6f6f
}

.buy-theme:hover,
.all-demo:hover {
    transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    right: 0px;
    background: #ff214f
}

.formpage .buy-theme span,
.formpage .all-demo span {
    padding: 0 9px;
    position: relative;
    top: 0;
    opacity: 0
}

.formpage .buy-theme:hover span,
.formpage .all-demo:hover span {
    opacity: 1;
    color: #fff
}

.formpage .buy-theme:hover i,
.formpage .all-demo:hover i {
    color: #fff
}

.formpage .buy-theme a,
.formpage .all-demo a {
    color: #232323;
    font-size: 10px;
    text-transform: uppercase;
    padding: 2px 10px;
    display: block;
    text-decoration: none;
    font-weight: 500
}

.formpage .all-demo {
    transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    position: fixed;
    top: 172px;
    right: -105px;
    background: #fff;
    z-index: 1000;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999
}

header a.logo{
    z-index: 9;
}

@media (max-width: 767px) {

.mtr-text {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
}

.centa-mob{
text-align: center;
}

.mtr-sm {
    margin-left: 0px;
}

.lp-main-container .mtr-pl{margin-bottom: 15px; margin-right: 0px;text-align: center;}
.accordion-menu{padding-bottom: 15px;position: relative!important;
    top: -41px;}

.login-desi{margin:10px 17px 16px;}

.left-mobi{justify-content: flex-start!important;}
.Early-Access-Release, .Made-For-a-Purpose {font-size: 30px;}
.purpose p{padding-bottom: 25px;}
.img-div img {
width: 100%;
height: auto;
margin-top: 42px;
}
.pt-110 {padding-top: 0px;}
.pt-80 {
    padding-top: 0px;
}
.row-mt {
    padding-left: 0px;
    padding-right: 0px;
}

.home-insight-card {
    width: 100%!important;
}

.sm-card {
    width: 100%;
    height: auto;
}
.footer .col-wd-1 {
    flex: 0 0 100% !important;
    max-width: 100%!important;
}
.none-footer{
    display: none;
}
.contact-img{
    height: auto;
    margin-left: 0px;
}
.footer{
    height: auto;
}

.footer .col-wd-4 {
    flex: 0 0 100%!important;
    width: 100%!important;
}

.sm-card{
    margin-left: 0px;
}

.footer .ml-40 {
    margin-left: 0px;
}

}