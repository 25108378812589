.landing-page-main-class{
    .black-bg-section{
      background: linear-gradient(to right , #EAEAEA,#fff);
    }
  
section.section-1 {

 padding-top: 100px!important;
 padding-bottom: 0px!important;

  }
  .purpose p {
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #2b292d;
    margin: 0px 50px;
    font-size: 1rem;
}
  
  section.section-2 {
    padding: 50px 0;
  }
  
  section.section-3 {
    padding: 50px 0;
  }
  
.section-grey {
    padding: 50px 0;
    /* opacity: 0.3; */
    background: linear-gradient(to right , #b9b9b9,#fff);
  }
  .local-img{
    width: 100%;
  }
  .priceBtn{
    background-color:#1CAE6F !important;
    border-color:#1CAE6F !important;
    padding: 7px 55px;
    font-size: 15px;
}

  .title-heading {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    padding-bottom: 25px;
  }
  
  .home-in {
    text-decoration: none !important;
  }
  
  .in-cursor {
    cursor: pointer;
  }
  .font-size-12 {
    font-size: 15.5px;
   }
   .font-size-14 {
    font-size: 17px;
   }
   p {
    font-size: 1rem;
}
.Site-Heading{
  font-size:2.8rem;
  font-weight: bold;
  /* font-weight: 100; */
  color: #04021C;
}
.py-35{
  padding: 35px 0px;
}
  .Early-Access-Release,
  .Made-For-a-Purpose {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #131415;
    padding-bottom: 30px;

  }
 
  
  .section-1-title {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    font-size: 17px;
    font-weight: 500;
    // opacity: 0.8!important;
    color: #04021C;
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .custom-btn{
    padding: 15px 15px!important;
    font-size: 19px!important;
  }
  .img-div{
    object{
      width: 85%;
    }
  }
  .img-div .screenviewimg {
    width: 44rem;
    height: 38rem;
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .img-div  .relativeimg{
 position: absolute;
 padding: 0px 0px;
 top: 22rem;
 left: -1rem;
  }
  .img-div  .relativeimg2{
    position: absolute;
    padding: 0px 0px;
    top: 10.5rem;
    right: -9rem;
    max-width: 90% !important;
    height: 34%;
     }
  .Sign-up-for-Free  {
    padding: 0.6rem 2.7rem;
    font-weight: bold;
    letter-spacing: 1.7px;
    background-color: #1E1E1E !important;
    color: #fff;
    border-color:  #1E1E1E!important;
    font-size: 17px !important;
    border-radius: 6px!important;
}
  
  .white-bg{
    background-color: #fff!important;
  }
  .section-2 .ticker-sm img {
    border-radius: 25%;
    object-fit: contain;
    width: 24px;
    height: 24px;
  }
  
  .section-2 .ticker-num{
    font-size: 26px;
    font-weight: bold;
    color: #2b292d;
    vertical-align: middle;
    padding-left: 10px;
  }
  
  .section-2 .ticker-title {
    font-weight: 600;
    color: #2b292d;
    padding-top: 5px;
  }
  
  .purpose-sm .purpose-heading{
    font-weight: 600;
    padding-top: 15px;
    color: #000;
  }
  
  .purpose-sm  img {
    object-fit: contain;
    width: 50px;
    height: 50px;
  }
  .yellowBG{
    background-color: #FDF2D6;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    
  }
  // .list-items  li:before{
  //   content:"-"
  // }
  .height48{
    height: 55px !important;
    width: 48px !important;
    padding: 5px;
  }
  .purpose-sm .purpose-grey-text{
    line-height: 1.2;
    color: #000000;
    padding-top: 10px;
    opacity: 0.7;
  }
  .lp-main-container-2{
    max-width: 1300px;
  }
  

  
  .purpose p {
    font-weight: 500;
    line-height: 1.33;
  
    color: #2b292d;
 
  }
  .p-0{
    padding: 0!important;
  }
  .blue-section {
    background-color: #2b3e9d;
    padding: 40px 0 !important;
    position: relative;
  }
  
  
  img.img-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  img.img-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
  }
  
  .brand-tag {
    font-weight: bold;
    line-height: 1.21;
    text-align: center;
    color: #131415;
  }
  
  .service-tag {
    font-weight: bold;
    line-height: normal;
    color: #999999;
    padding-bottom: 15px;
  }
  
  .mtr-pl {
    font-weight: 500;
    line-height: 1.33;
    color: #2b292d;
    margin: 0px 50px 0 0;
    text-align: justify;
  }
  
  .mtr-sm {
    margin-left: -12px;
  }
  
  .mtr-sm img {
    border-radius: 25%;
    object-fit: contain;
    width: 30px;
    height: 40px;
  }
  
  .mtr-text {
    margin-top: -31px;
    margin-left: 40px;
    width: 60%;
  }
  
  .mtr-heading {
    font-weight: 600;
    padding-bottom: 10px;
  }
  
  .mtr-grey-text {
    opacity: 0.7;
    line-height: 1.2;
    color: #000000;
  }
  
  p.feature {
    font-weight: bold;
    line-height: 1.55;
    color: #f1592b;
    padding-bottom: 10px;
  }
  
  p.feature-pl {
    font-weight: 600;
    line-height: 1.65;
    letter-spacing: normal;
    color: #666666;
  }
  
  .feature-img {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    /* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1); */
    background-blend-mode: overlay, normal;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
  }
  
  .home-insight-card {
    width: 235px !important;
    min-height: 361px;
    box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
    border-radius: 10px;
    background-blend-mode: overlay, normal;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #ffffff, #ffffff);
  }
  
  .home-insight-card .card-body {
    padding: 15px 15px !important;
  }
  
  .home-insight-card .card-pera {
     font-weight: 600;
     line-height: 1.25;
     text-align: center;
     color: #a0acba;
  }
  
  .home-insight-card .card-text {
    font-weight: bold;
    letter-spacing: -0.24px;
    text-align: center;
    color: #2b292d;
    padding-top: 10px;
  }
  
  .home-insight-card .card-img-top {
    border-radius: 10px;
  }
  
  .get-start-btn {
    padding: 8px 20px !important;
    font-size: 11px;
    background-color: #1b3272;
    color: #fff;
    border: 1px solid #1b3272;
    border-radius: 3px !important;
    font-weight: 600;
    letter-spacing: .5px;
  }
  
  .new-letter-img {
    width: 100%;
    height: auto;
    margin-top: -60px;
  }
  
  .started-btn {
    padding: 8px 20px;
    font-size: 12px;
    background-color: #3040c4;
    color: #fff;
    border: 1px solid #3040c4;
    border-radius: 3px !important;
  }
  
  .aml-api-pera {
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #2b292d;
    margin: 0px 50px;
  }
  
  #mc_form input {
    width: 68% !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 2px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -webkit-appearance: none;
    outline: none;
    max-width: 68%;
    margin-right: 0.25rem !important;
    margin-top: 10px;
  }
  
  .subscribe-hmc button {
    padding: 10px 20px !important;
    font-size: 12px !important;
    background-color: #3040c4 !important;
    color: #fff;
    border: 1px solid #3040c4 !important;
    border-radius: 3px !important;
  }
  
  .img-div .slick-list{
    height: 400px !important;
  }
  
  .early-h {
    font-weight: bold !important;
    padding-top: 10px;
    margin-bottom: 30px;
    color: #2a3e9d;
  }
  .demo-card{
    border-radius: 32px;
  }
  .my-6{
    margin: 4rem 0rem;
  }
  @media (max-device-width:500px){
    .trialButton , .Sign-up-for-Free, .Sign-up-for-Free-2{
      padding: 0.6rem 0.7rem!important;
      font-size: 15px!important;
    }
  }
  @media  (min-device-width:769px)and(max-device-width: 1440px) {
    section.section-1 {
      padding-bottom: 100px!important;
     
       }
.Early-Access-Release, .Made-For-a-Purpose {
    font-size: 30px;
}
.text-sm1-center{
  text-align: center!important;
}
.text-sm1-center p{
  font-size: .9375rem;
}
.img-div .screenviewimg{
  max-width: 100%;
  padding-top: 20px!important;
}
.img-div  .relativeimg{
  max-width: 50%;
  position: absolute;
  padding: 0px 0px;
  top: 12rem;
  left: -1rem;
   }
   
   .img-div .relativeimg2{
    position: absolute;
    padding: 0px 0px;
    top: 6.5rem;
    right: 3rem;
    /* max-width: 109% !important; */
    height: 30%;
    width: 30%;
}
  
  }
  .black-border{
    border: 2px solid black!important;
  }
  .trialButton:active ,.trialButton:focus{
    color: #000000!important;
  }

  @media (max-device-width :767px){
    section.section-1 {
      padding-bottom: 100px!important;
     
       }
       .img-div .screenviewimg{
        max-width: 100%;
        padding-top: 20px!important;
      }

    .img-div .relativeimg2{
      position: absolute;
      padding: 0px 0px;
      top: 4.5rem;
      right: 2.5rem;
      /* max-width: 109% !important; */
      height: 26%;
      width: 27%;
  }
  .img-div  .relativeimg{
    max-width: 50%;
    position: absolute;
    padding: 0px 0px;
    top: 8rem;
    left: -1rem;
     }
  // @media(max-device-width:820px) {
  //   .img-div.relativeimg{
  //     max-width: 50%;
  //     position: absolute;
  //     padding: 0px 0px;
  //     top: 18rem;
  //     left: -1rem;
  //   }
  //   .img-div.relativeimg2{
  //     position: absolute;
  //   padding: 0px 0px;
  //   top: 8.5rem;
  //   right: 4rem;
  //   /* max-width: 109% !important; */
  //   height: 30%;
  //   width: 30%;

  //   }
  // }
    }
    @media (max-device-width :450px){
      .img-div .screenviewimg{
        width: 100%!important;
        height: auto!important;
        padding-top: 20px!important;
      }

    }
    @media (width:768px) {
      section.section-1 {
        padding-bottom: 100px!important;
       
         }
      .img-div .screenviewimg{
        max-width: 100%;
        padding-top: 20px!important;
      }
      .img-div .relativeimg2{
        position: absolute;
        padding: 0px 0px;
        top: 8.5rem;
        right: 4rem;
        /* max-width: 109% !important; */
        height: 30%;
        width: 30%;
    
      }
      
    }
    @media  (max-width: 800px) {
      .img-div .screenviewimg{
        width: 37rem!important;
        height: 37rem!important;
      }
    }
    .plan-card{
      width: 285px;
    }
    .plan-card:hover{
      
          background-color: #1CAE6F;
        
      
        .card-text {
          color: #fff;
        }
        .plan-free{
          color: #fff;
        }
        .plan-body-li li{
          color: #fff;
        }
        .priceBtn{
          background-color: #fff!important;
          border-color: #fff!important;
          color: #1CAE6F!important;
        }
      }
    .list-items{
      li {
        font-size: 15px;
        font-weight: 500;
        opacity: 0.8;
        color: #04021C;
      }
    }
  .WeeklyNewsletter{
    color: #0C0C0C;
    font-weight: 700;
    font-size: 40px !important;
    line-height: 50px !important;
  }
  .mailchimp-btn input{
    padding: 14px;
    width: 80%;
    border: none;
    border-radius: 4px 0px 0px 4px;
    font-size: 16px;
    line-height: 21px; 
    margin-right: 10px;
    flex-grow: 1;
    flex-basis: calc(100% / 3);
  }
  .mailchimp-btn button{
    padding: 13px 50px;
    font-size: 18px;
    line-height: 27px;
    border: none;
    background: #1E1E1E;
    border-radius: 8px;
    color: #fff;
    flex-grow: 1;
    flex-basis: calc(100% / 7);
  }
  .msg-alert{
    flex-basis: 100%; display: flex;
    p{
      margin-bottom: 0rem;
    }
  }
  .fadeInUp {
    animation: fadeInUp;
    animation-duration: 2s;
}
@keyframes fadeInUp {
   0% {
  opacity: 0;
  transform: translate3d(0,100%,0);
}

100% {
  opacity: 1;
  transform: none;
}
}
.dflex{
  display: flex;
  flex-wrap: wrap;
}


@media (max-device-width :767px) {
  .mailchimp-btn button{
    padding: 13px 20px!important;
  }
}
@media (max-device-width:820px) {
  .mailchimp-btn button {
    padding: 13px 18px!important;
  }
}
.listImage{
  height: 22px;
  width:22px;
  padding-top: 10px;
  img{
    height: 12px;
  width: 27px;
  }
}
.listItem{
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}
.fontrandom{
  color: #0E204E;
  font-size: 40px !important;
  line-height: 50px !important;
  font-weight: 700;
  padding-bottom: 30px;
  span{
    font-weight: 700 !important;
  color: #6EA7DC !important;
  }
}
.listGroup:hover{
  .listItem{
    font-weight: 600 !important;
  }
  .listImage img{
  content: url("../../assets/Blue Arrow.png");
  }
}
.trialButton{
  padding: 0.6rem 2.7rem;
  font-weight: bold;
  letter-spacing: 1.7px;
  text-align: center;
  background-color: transparent!important ;
  color: #000 !important;
  border-color: #000 !important;
  font-size: 16px !important;
  border-radius: 6px !important;
  border-width: 2px;
}
  }
