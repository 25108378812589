.bell-icon-wrapper {
    float: right;
  }
  .notification-icon-container {
    position: relative;
    top:-6px;
    img{
      border-radius: 0%!important;
      cursor: pointer;
    }
  }
  .white{
    color: #fff!important;
  }
  .notifyClearButton {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid #ddd;
  }
  .notifyBell
  {
    width: 26px;
  }
  .notifyClearButton .btn {
    float: right;
    color: #000;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
  }
  .notifyClearButton .btn:hover {
    color: #000 !important;
    text-decoration: underline;
  }
  .bell-icon-badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 4px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    font-size: 0.6rem;
  }
  
@media (min-width:1000px){
    .notifications-container {
        position: absolute;
        width: 350px;
        height: 500px;
        overflow-y: auto;
        z-index: 1;
        top: 56px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 2px 6px 6px 2px rgba(0, 0, 0, 0.25);
        right: -1px;
        animation: hideMe 1s forwards;
        padding: 15px 25px 15px 25px;
      }
      @keyframes hideMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}
@media (min-width:720px)and (max-width:999px){
    .notifications-container {
        position: absolute;
        width: 300px;
        height: 400px;
        overflow-y: auto;
        z-index: 1;
        top: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 2px 6px 6px 2px rgba(0, 0, 0, 0.25);
        left: 0;
        animation: hideMe 0.7s forwards;
        padding: 15px 25px 15px 25px;
      }
      @keyframes hideMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
      .notification-icon-container{
        top:0px ;
        margin-top:9px;
        margin-bottom:9px;
      }

      
}
.d-block{
  display: block;
  .svg{
    position: absolute;
    right: 5px;
    width: 2rem !important;
    height: 2.2rem !important;
    color: #fff !important;
    border-color: #FFFFFF;
    top: 18px;
    z-index: 1;
    animation: hideMe 1s forwards;
  }
}
.margin-3{
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
}
@media  (max-width:719px){
    .notifications-container {
        position: absolute;
        width: 200px;
        height: 200px;
        overflow-y: auto;
        z-index: 1;
        top: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow:2px 6px 6px 2px rgba(0, 0, 0, 0.25);
        left: 0;
        animation: hideMe 0.7s forwards;
        padding:15px 25px 15px 25px;
      }
      @keyframes hideMe{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
      .notification-icon-container{
        top:0px ;
        margin-top:9px;
        margin-bottom:9px;
    
      }

      
}
  .notification-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  
  .notification-wrapper {
    padding: 10px;
    margin: 0;
  }
  
  .notification {
    list-style: none;
    margin-top: 5px;
    text-align: left;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    .logo{
     width:8.2rem!important;
      height: auto!important;
      border-radius: 0%!important;
    }
    .logo-product{
      width: 9.2rem !important;
      height: auto !important;
      border-radius: 0% !important;
    }
    .fa-arrow-right{
      height: 20px;
      width: 30px;
      opacity: 0;
    }
    }
    .relative{
      position: relative;
    }
    .text-right{
      position: absolute;
      right: 5px;
      top:15px
    }
    // display: flex;

  .notification span {
    font-size: 0.75rem;
  }
  .notification-text {
    font-size: 12px!important;
    font-weight: 400!important;
    color: #323232;
    margin: 0 !important;
    font-style: italic;
    margin-bottom: 1rem!important;
  }
  .notification-text-heading{
    margin: 0px !important;
    color: #000000;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 1rem !important;
  }
  .react-confirm-alert-button-group > button:first-child {
    background: #000;
  }
  .unread {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: rgb(255, 153, 0);
    align-self: center;
  }
  .notifications-container::-webkit-scrollbar-track {
    //  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .notifications-container::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  
  .notifications-container::-webkit-scrollbar-thumb {
    background-color: #000;
    opacity: 0.5;
  }
  .zigram-arrow{
    color: #6EA7DC;
    
  }
  .aml-arrow{
    color: #090763;
    
  }
  .DA-arrow{
    color:#1CAE6F;
  }
  .psio-arrow{
    color:#7854F7;
  }
  .DAB-arrow{
    color: #000000;
  }
  .notification:hover{
    background-color: #F7F7F7;
    .fa-arrow-right{
      opacity: 1!important;
    }
  }